@import "../variables";

.k-numerictextbox .k-select {
  width: calc(2rem + 8px);
}

/* Kendo Numeric Textbox Normal Style */

.k-numerictextbox .k-numeric-wrap {
  background-color: $base-text;
  color: $base-bg;
}

/* Kendo Numeric Textbox Dark Background */

.k-numerictextbox.dark-bg > .k-numeric-wrap {
  border-color: $base-text;
  color: $base-text;
  background-color: $base-bg;
}

.k-numerictextbox.dark-bg > .k-numeric-wrap > .k-input {
  color: $base-text;
}

.k-numerictextbox.dark-bg > .k-numeric-wrap .k-select {
  background-color: $base-bg;
  color: $base-text;
}

.k-numerictextbox.dark-bg > .k-numeric-wrap:hover .k-select {
  background-color: $base-bg;
  color: $accent;
}

.k-numerictextbox.dark-bg > .k-numeric-wrap:hover {
  border-color: $accent;
}

.k-numerictextbox.dark-bg > .k-numeric-wrap:hover > .k-input {
  color: $accent;
}

.k-numerictextbox.dark-bg > .k-numeric-wrap:hover .k-link {
  background-color: $base-bg;
}

span.k-link.k-link-increase,
span.k-link.k-link-decrease {
  background-color: $base-bg;
  color: $base-text;
}

.k-numerictextbox .k-select .k-link:hover, .k-numerictextbox .k-select .k-link.k-state-hover {
  background-color: $base-bg;
}

.ap-readonly .k-select {
  display: none;
}
