@import "../variables";

/* General */
.ap-cr-border-left {
  border-left: $cr-border !important;
}

.ap-cr-border-right {
  border-right: $cr-border !important;
}

.ap-cr-hidden-border-left {
  border-left: $cr-border !important;
  border-left-color: transparent !important;
}

.ap-cr-cell-marked-bg {
  background-color: $selected-text;
}

.ap-cr-flex-box-center {
  text-align: center;
  align-self: center;
}

.ap-cr-flex-box-middle {
  align-self: center;
}

.ap-cr-flex-box {
  display: flex;
}

.ap-cr-flex-header {
  display: flex;
  align-items: center;
}

.ap-cr-checkbox-margin-11px {
  margin-left: 11px;
}

.ap-cr-no-border-left {
  border-left: none !important;
}

.ap-cr-full-height {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ap-cr-three-quarter-width {
  width: 75%;
}

.ap-cr-width-60px {
  width: 60px;
}

.ap-cr-full-width {
  width: 100%;
}

.ap-cr-width-250px {
  min-width: 250px;
  max-width: 250px;
}

.ap-cr-width-35px {
  min-width: 35px;
  max-width: 35px;
}

.ap-cr-width-50px {
  min-width: 50px;
  max-width: 50px;
}

.ap-cr-width-51px {
  min-width: 51px !important;
  max-width: 51px !important;
}

.ap-cr-width-180px {
  min-width: 180px;
  max-width: 180px;
}

.ap-cr-column-box-middle-34px-border-left {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 18px;
  max-width: 18px;
  border-left: none;
}

.ap-cr-column-box-middle-35px-border-left {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 35px;
  max-width: 35px;
  border-left: none;
}

.ap-cr-column-box-middle-placeholder-61px {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  margin-left: $cr-data-child-placeholder-width;
  border-left: none;
  min-width: 61px;
  max-width: 61px;
}

.ap-cr-row-data-box {
  display: flex;
  height: $cr-small-row-height*3;
}

.ap-cr-column-box-middle-33px {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 17px;
  max-width: 17px;
}

.ap-cr-column-box-middle-34px {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 18px;
  max-width: 18px;
}

.ap-cr-column-box-middle-50px {
  min-width: 50px;
  max-width: 50px;
}

.ap-cr-column-box-middle-60px {
  min-width: 60px;
  max-width: 60px;
}

.ap-cr-column-box-middle-60px,
.ap-cr-column-box-middle-50px {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
}

.ap-cr-column-box-middle-163px {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 163px;
  max-width: 163px;
}

.ap-cr-column-box-middle-180px {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 180px;
  max-width: 180px;
}

.ap-cr-flex-box-margin-11px {
  display: flex;
  margin-left: 11px;
}

.ap-cr-row-main-data-box {
  display: flex;
  border-top: none;
  height: $cr-small-row-height*3;
}

.ap-cr-row-main-data-box:nth-child(even) {
  background-color: $light-bg-color;
}

.ap-cr-row {
  display: flex;
  height: $cr-small-row-height;
}

.ap-cr-flex-box-checkbox-11px {
  display: flex;
  margin-left: 11px;
}

.ap-cr-cell-right-aligned {
  text-align: right
}

.ap-cr-cell-noselect {
  height: $cr-small-row-height - ($cr-cell-padding-top * 2);
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  text-align: center;
  align-self: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.ap-cr-header-cell-detail {
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  display: flex;
  align-items: center;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  min-width: 18px;
  max-width: 18px;
  height: $cr-small-row-height - ($cr-cell-padding-top * 2) - $small-border-size;
  text-align: center;
  align-self: center;
  border-left: none !important;
}

.ap-cr-cell-clickable-noselect-33px {
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 33px;
  max-width: 33px;
  height: $cr-header-detail-height - ($cr-cell-padding-top * 2) - $small-border-size;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.ap-cr-cell-clickable-noselect-34px {
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 17px;
  max-width: 17px;
  height: $cr-header-detail-height - ($cr-cell-padding-top * 2) - $small-border-size;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.ap-cr-cell-clickable-noselect-163px {
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 163px;
  max-width: 163px;
  height: $cr-header-detail-height - ($cr-cell-padding-top * 2) - $small-border-size;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.ap-cr-cell-clickable-noselect-180px {
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  height: $cr-header-detail-height - ($cr-cell-padding-top * 2) - $small-border-size;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.ap-cr-cell-clickable-noselect-50px {
  min-width: 50px;
  max-width: 50px;
}

.ap-cr-cell-clickable-noselect-60px {
  min-width: 60px;
  max-width: 60px;
}

.ap-cr-cell-clickable-noselect-60px,
.ap-cr-cell-clickable-noselect-50px {
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: $cr-header-detail-height - ($cr-cell-padding-top * 2) - $small-border-size;
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.ap-cr-cell {
  height: $cr-small-row-height - ($cr-cell-padding-top * 2);
  border-left: $cr-border;
  border-bottom: $cr-border;
  line-height: $cr-line-height;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
}

.ap-cr-scroll-bar-placeholder {
  min-width: 30px;
  max-width: 30px;
}

.ap-cr-crop-row {
  height: $cr-small-row-height;
  min-width: 150px;
  max-width: 1000px;
}

.ap-cr-crop-row-detail-box:hover {
  .ap-cr-inline > button.ap-cr-add-button {
    display: inherit;
  }
}

.ap-cr-crop-row-detail-box {
  height: $cr-small-row-height;
  min-width: 150px;
  max-width: 1000px;
  color: $base-text;
  border-bottom: $cr-border;
  border-left: $cr-border;
  line-height: $cr-line-height;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
}

.ap-cr-back-row-noselect {
  min-width: 50px;
  max-width: 50px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */

  label > input,
  input {
    background-color: rgba(176, 203, 31, 0.01);
    border-color: rgba(176, 203, 31, 0.01);
  }
}

.ap-cr-front-row-noselect {
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
  kendo-combobox {
    width: 100%;
  }
}

.ap-cr-crop-row-big-detail-box:hover {
  .ap-cr-inline > button.ap-cr-add-button {
    display: inherit;
  }
}

.ap-cr-crop-row-big-detail-box {
  height: $cr-small-row-height*2;
  min-width: 150px;
  max-width: 1000px;
  color: $base-text;
  border-bottom: $cr-border;
  border-left: $cr-border;
  line-height: $cr-line-height;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
}

.ap-cr-header-border-box {
  border-right-width: $small-border-size;
  border-right-style: solid;
  border-right-color: $base-bg;
  border-top-width: 0px;
  border-top-style: solid;
  border-top-color: $base-bg;
  border-left-width: 0;
}

.ap-cr-row-fields .ap-cr-header-border-box:not(.ap-cr-cell-header-marked) {
  border-left-width: 1px;
  border-right-width: 0; // $small-border-size;
  border-right-style: solid;
  border-left-style: solid;
  border-image: linear-gradient(to top, $grid-header-border-color 60%, rgba(0, 0, 0, 0)) 1 100%;
  border-top-width: 0;
  border-bottom-width: 0;
}

/*.ap-cr-row .k-icon {
  color: $bg-color !important; //override AC default here
}*/

.ap-cr-crop-row-big {
  height: $cr-small-row-height*2;
  min-width: 150px;
  max-width: 1000px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

.clickable {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

@import "ap-crop-rotation-grid";
@import "ap-crop-rotation-header";
@import "ap-crop-rotation-field-list";
@import "ap-crop-rotation-detail";
