@import "../variables";

.k-slider.dark-bg .k-button {
  border-color: $base-text;
  height: 25px;
  width: 25px;
  margin-top: 2px;
}

.k-slider.dark-bg .k-button:hover,
.k-slider.dark-bg .k-button:active,
.k-slider.dark-bg .k-button:focus {
  border-color: $accent;
}

.k-slider .k-draghandle {
  height: 10px;
  width: 10px;

}
