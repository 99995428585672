@import "../variables";

kendo-splitter {
  border: 0px solid black !important;
}

kendo-splitter-bar {
  background: $base-bg !important;
  color: $base-text !important;
}

.k-splitbar-static-horizontal {
  width: 0px;
}

.k-splitbar-static-vertical {
  height: 0px;
}
