@import "../variables";

.k-multiselect.dark-bg {
  margin-bottom: 8px;
}

.k-multiselect.dark-bg .k-multiselect-wrap, .k-dropdowntree .k-multiselect-wrap {
  border-color: $base-text;
}

.k-multiselect.dark-bg .k-multiselect-wrap:hover {
  border-color: $accent;
}

.k-multiselect.dark-bg .k-multiselect-wrap kendo-taglist .k-button {
  border-color: $base-text;
  background-color: $base-bg;
  color: $base-text;
}

.k-multiselect.dark-bg .k-multiselect-wrap:hover kendo-taglist .k-button {
  border-color: $accent;
  background-color: $base-bg;
  color: $base-text;
}

.ap-dynform-fieldset .k-multiselect-wrap {
  background-color: $blank;
}
