.k-menu .k-item > .k-link > .k-icon, .k-menu .k-item > .k-link > .k-image, .k-menu .k-item > .k-link > .k-sprite {
  display: none;
}

kendo-popup span.k-icon.k-menu-expand-arrow.k-i-arrow-60-right.ng-star-inserted {
  display: contents;
}

.k-menu-group .k-item > .k-link, .k-menu-vertical .k-item > .k-link:hover {
  ap-icon-selector {
    cursor: pointer;

    .ap-icon {
      cursor: pointer;

      svg {
        polygon.hoverFill {
          fill: $accent !important;
          stroke: $accent !important;
        }

        path.hoverFill {
          fill: $accent !important;
          stroke: $accent !important;
        }

        rect.hoverFill {
          fill: $accent !important;
          stroke: $accent !important;
        }
      }
    }
  }
}

.k-menu-group .k-item > .k-link, .k-menu-vertical .k-item > .k-link {
  padding: 0;
  border: $small-border-size solid #2c3237;
  border-left: 2px solid #2c3237;
}


