@import "../variables";

ap-section-header {
  h1 {
    padding-left: 10px;
    margin: 0;
  }
}

.ap-section-header {
  display: flex;
  height: $header-height;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: $header-padding;
}

.ap-section-header-input-group {
  display: flex;
  margin-right: 5px;

  input,
  input:hover,
  input:focus {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right-width: 0;
  }

  button {
    width: 30px;
    border: $small-border-size solid black !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ap-section-header-wrapper {
  align-items: center;

  .ap-section-header-create-button {
    width: 62px;
    padding: 4px 8px;
    font-size: 0.9rem;
  }
}

.ap-section-header button,
.ap-section-header input {
  height: 32.39px;
}

.ap-section-header .k-button .k-icon,
.ap-main-view .k-button .k-icon {
  color: $base-bg;
}

ap-section-header-grid,
.ap-section-header-wrapper,
.ap-section-header-template {
  width: 100%;
  display: flex;
}

.ap-section-header-icon-wrapper {
  height: 30px;
}

.ap-section-header-caption {
  width: 100%;
  color: $base-bg;

  h1 {
    font-size: 30px;
  }
}

.ap-section-header-icon {
  width: 36px;
  color: $base-bg;

  svg {
    height: 30px;
    width: 30px;

    polygon {
      fill: $base-bg;
      stroke: $base-bg;
    }

    path {
      fill: $base-bg;
      stroke: $base-bg;
    }

    rect {
      fill: $base-bg;
      stroke: $base-bg;
    }
  }
}

.ap-section-header-controls {
  display: flex;
}

button.ap-section-header-control-button {
  width: 36px;
  height: 36px;
  background: transparent;
  border: none;
}

button.ap-section-header-control-button:hover,
button.ap-section-header-control-button:focus {
  background: $hovered-button-light-bg;
}

.ap-section-header-input-group > .k-button {
  border-radius: 0;
  background-color: $accent;
  color: $base-bg;
}

.ap-section-header-input-group > .k-button:nth-child(3) {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
