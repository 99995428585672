@import "../variables";

.ap-textbox-white-background {
  background-color: #ffffff !important;
}

.ap-note {
  font-size: $input-label-font-size;
  line-height: 1rem;
  margin-bottom: -1rem;
}
