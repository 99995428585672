@import "../variables";

.ap-farm-panel {
  margin-left: 90px;
  width: 100%;
  position: relative;
  display: flex;
  padding-bottom: 2px;
  overflow: hidden;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.ap-button-invisible:hover {
  color: $accent;
}

ap-current-time {
  line-height: 34px;
}

.ap-farm-panel .ap-toolbar-popup-header {
  text-align: center;
  color: $accent;
  line-height: $header-height;
}

.ap-farm-panel .ap-no-items-available-text {
  text-align: center;
  padding: 13px 12px 15px;
  line-height: $header-height;
}
