@import "../variables";

.k-checkbox-label {
  line-height: $main-line-height;
}

input[type="checkbox"], input[type="radio"] {
  border: 0.0625rem solid $input-border-color;
}

input[type="checkbox"]:hover, input[type="radio"]:hover,
input[type="checkbox"]:focus, input[type="radio"]:focus {
  border: 0.0625rem solid $hovered-input-border-color;
}

.k-checkbox:checked {
  border-color: $accent !important;
  color: $base-bg !important;
  background-color: $accent !important;
}

.k-checkbox:indeterminate {
  background-color: $accent !important;
}

.k-checkbox:checked:focus, .k-checkbox.k-checked.k-state-focus {
  box-shadow: 0 0 0 2px $accent-30perc;
}

.k-checkbox:indeterminate, .k-checkbox.k-state-indeterminate, .k-checkbox.k-indeterminate {
  color: $base-bg;
}
