@import "../variables";

.ap-cr-header {
  height: $cr-header-height;
  background-color: $base-bg;
}

.ap-cr-row-empty {
  min-width: $cr-header-field-width;
  max-width: $cr-header-field-width;
  background-color: $blank;
  height: $cr-small-row-height;
}

.ap-cr-row-fields {
  display: flex;
  height: $cr-header-detail-height;
}

.ap-cr-cell-detail-height {
  height: $cr-header-detail-height - ($cr-cell-padding-top * 2) - $small-border-size;
}

.ap-cr-cell.ap-cr-cell-header {
  color: $base-text;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
}

.ap-cr-cell-height-3x {
  height: $cr-small-row-height - ($cr-cell-padding-top * 2) - $small-border-size;
}

.ap-cr-cell-header-marked {
  background-color: $marked-cell;
}

.ap-cr-header-full-height {
  height: calc(100% - 12px);
}

.ap-cr-filter {
  padding: .5em 1em;
}

.ap-cr-filter {
  padding: .5em 1em;
}

.ap-cr-header .k-popup .k-actions,
.ap-cr-header .k-popup .k-action-buttons,
.ap-cr-header .k-popup .k-columnmenu-actions {
  margin-top: 0 !important;
}

.ap-cr-header .k-popup .k-actions,
.ap-cr-header .k-popup .k-action-buttons,
.ap-cr-header .k-popup .k-columnmenu-actions {
  padding-top: 0 !important;
}

.ap-cr-header .ap-cr-filter .k-widget.k-numerictextbox {
  margin: 8px 8px 0;
}

.ap-cr-header ap-crop-rotation-filter .k-action-buttons {
  justify-content: space-between;
}

.ap-cr-header ap-crop-rotation-filter ap-number-filter > fieldset {
  margin-left: -5px;
  margin-right: -5px;
}

.ap-cr-header .k-input {
  font-weight: $cr-data-font-weight
}
