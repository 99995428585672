@import "variables";

/*Styling for ng-scrollbar*/
ng-scrollbar {
  --scrollbar-size: 8px;
  --scrollbar-thumb-color: #{$grid-header-bg-color} !important; /* using color variables not working for scrollbar!*/
  --scrollbar-thumb-hover-color: #{$accent} !important; /* using color variables not working for scrollbar!*/
  --scrollbar-border-radius: 4px;
}

/* Force base Text Color */
.ap-base-text {
  color: $base-text;
  font-size: 15px;
  padding-left: 6px;
}

/* Main Layout */
.ap-layout-wrapper {
  height: $full-percent;
  width: $full-percent;
  min-width: 520px;
  min-height: 600px;
}

.ap-sidebar,
.ap-header {
  background: $base-bg;
  color: $base-text;
  z-index: $z-mid;
}

.ap-header {
  height: $header-height;
  display: flex;
  justify-content: flex-end;
}

.ap-main-content {
  height: -webkit-calc(#{$full-percent} - #{$header-height});
  height: -moz-calc(#{$full-percent} - #{$header-height});
  height: calc(#{$full-percent} - #{$header-height});
  display: flex;
  overflow: hidden;
}

.ap-main-view, .ap-map-view {
  width: $content-view-width;
  border: 0;
  height: -webkit-calc(#{$full-percent});
  height: -moz-calc(#{$full-percent});
  height: calc(#{$full-percent});
  overflow: auto;
}

.ap-map-view > div {
  height: 100%;
}

.ap-map-view {
  width: $content-view-width;
  border-left: $small-border-dark;
}

.ap-main-view {
  overflow: hidden;
  z-index: $z-low;
  position: relative;
}

.ap-main-view > div {
  height: 100%;
}

.ap-tab-view {
  width: $content-view-width;
  border-left: $small-border-dark;
  z-index: $z-mid;
}

.ap-modal-view {
  z-index: $z-maximum;
}

.ap-box-shadow-left {
  box-shadow: -50px 0 50px rgba(0, 0, 0, 0.5);
}

/* Logo */
.ap-logo {
  height: $logo-height;
  width: $logo-width;
  position: absolute;
  top: $logo-top;
  left: $logo-left;
  z-index: $z-high;
}

/* Sidebar */
.ap-sidebar {
  padding-top: $header-height;
  width: $sidebar-open-width;
  flex-basis: $sidebar-open-width;
  border: $small-border-dark;
  padding-left: 5px;
}

.ap-sidebar > ap-menu > div {
  //height: 100%;
}

/* Sidebar Toggle Button */
.ap-sidebar-toggle {
  display: flex;
  height: $header-height;

  p {
    margin: 7px;
  }

  span {
    font-size: $header-height !important;
    color: $icon-dark;
  }
}

.ap-sidebar-toggle:hover, .ap-sidebar-toggle:focus {
  background-color: $hovered-bg;
  color: $accent;
  cursor: pointer;

  span {
    color: $accent;
  }
}

.ap-responsive-text {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ap-responsive-text-multiline {
  width: 100%;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

ap-property-column-header > ap-responsive-text {
  display: inline-grid;
}

.ap-responsive-text.inline-grid {
  display: inline-grid;
}

.ap-tooltip {
  display: none;
  max-height: 300px;
  background-color: $base-bg;
  color: $base-text;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: $z-tooltip;
  font-size: 0.9rem;
  font-weight: lighter;
  line-height: 0.9rem;
}

.ap-pagerElement {
  margin-right: 10px !important;
  display: inline-block !important;
}

/* Small Menu */
ul.k-group.k-menu-group.ng-star-inserted.k-popup.k-reset {
  width: 250px !important;
  overflow: hidden !important;
}

.trafficlightred {
  background: #990001;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
}

.trafficlightyellow {
  background: #e2d100; //#f9ff05;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
}

.trafficlightgreen {
  background: #b0cb1f; //#a6cc1f;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  width: fit-content;
  float: left;
}

.float-right {
  width: fit-content;
  float: right;
}

.ap-debug-indicator {
  position: absolute;
  color:red;
  background-color:red;
  width:100%;
  height:1px;
  font-size:10px;
}

.ap-offline-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.25);
  z-index: 999;
}

.ap-fullscreen-spinner {
  position: fixed;
  width: 20%;
  top: 50%;
  left: 50%;
  margin-top: -10%;
  margin-left: -10%;
  padding: 10px;
  text-align: center;
}

.ap-error-image {
  background-image: url("/assets/svg/tractor_error.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 140px;
  width: 140px;
  display: block;
  margin: 0 auto;
  position: relative;
}
