@import "variables";

.ap-login-screen {
  width: $full-percent;
  height: $full-percent;
  min-height: 670px;
  background-image: url("/assets/img/tmp/header_loginScreen.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.ap-login-screen .k-form .k-progressbar.k-widget {
  display: grid;
}

.ap-login-screen .k-splitter-flex .k-pane {
  height: auto;
}

.ap-login-logo {
  width: 270px;
  height: 93px;
  margin: 0 auto;
  padding: 60px;
}

@media screen and (min-width: $login-content-width) {
  .ap-login-content {
    width: $login-content-width;
  }
  .ap-login-splitter-pane {
    padding: 30px 75px 0 75px;
  }
}

@media screen and (max-width: $login-content-width) {
  .ap-login-content {
    width: 100%;
  }
  .ap-login-splitter-pane {
    padding: 30px 0 0 0;
  }
}

.ap-login-dynforms-actions {
  padding-left: 0.625em;
  padding-right: 0.625em;
}

.ap-login-content {
  position: relative;
  height: $full-percent;
  margin: 0 auto;
  background-color: $blank;
}

.ap-login-splitter-pane {
  min-height: 350px
}

.ap-login-splitter-pane-header.k-pane {
  overflow-y: hidden;
  min-height: 153px
}

.ap-login-content .ap-form-content {
  padding-bottom: 0;
  height: 330px;
}

.ap-loginContentBlending66 {
  content: '';
  width: 808px;
  left: calc(50% - 404px);
  transition: opacity 600ms ease-in 100ms, ease-in;
  transition: transform 600ms ease-in, opacity 600ms ease-in 100ms;
  transition: transform 600ms ease-in, opacity 600ms ease-in 100ms, ease-in;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: $blank;
  opacity: 0.33;
}

.ap-loginContentBlending33 {
  content: '';
  width: 848px;
  left: calc(50% - 424px);
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: $blank;
  opacity: 0.33;
}

.ap-login-footer {
  border-top: 0.1875rem solid $login-footer;
  padding: 27px !important;
  background-color: $base-bg;
  transition: opacity 300ms ease-out;
  text-align: center;
}

ap-login-form .k-loading-image {
  height: 410px;
  background-color: white;
  opacity: 0.4;
}

.ap-login-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left
}
