@import "../variables";

.k-content.k-window-content.k-dialog-content {
  padding: 0;
  display: contents;
}

.k-form {
  font-size: $k-form-font-size;
  color: $base-bg;
}

.k-form fieldset legend, .k-form-inline fieldset legend {
  color: $base-bg;
}

.ap-fieldset {
  border-top: $small-border-size solid $input-border-color;
  border-bottom: none;
  border-left: none;
  border-right: none;

  legend {
    font-size: 19px !important;
  }
}

.ap-field-set-no-legend-line {
  border-top: none;
}

.ap-error-no-border .ap-dynform-element .k-textbox:disabled,
.ap-error-no-border .ap-dynform-element .ap-textbox-container:disabled {
  border: none;
  padding: 0;
  opacity: 1;
}

.k-link.k-link-increase,
.k-link.k-link-decrease, {
  height: auto;
}

.k-form.k-state-disabled,
.k-form.k-disabled {
  opacity: initial;
  filter: none;
}
