@import "../variables";

.k-window {
  border-color: transparent;
  background-color: $base-text;
  color: $base-bg;
}

.k-dialog-buttongroup .k-button:not(.k-primary) {
  border-color: $button-gray-border-color;
  background-color: $button-gray-bg-color;
  color: $base-bg;
}

.k-dialog-buttongroup .k-button.k-primary {
  border-color: $button-border-color;
  background-color: $accent;
  color: $base-bg;
}

.k-dialog-buttongroup .k-button.k-primary:hover,
.k-dialog-buttongroup .k-button.k-primary:active,
.k-dialog-buttongroup .k-button.k-primary:focus {
  border-color: $hovered-button-border-color;
}

.k-dialog-buttongroup .k-button:not(.k-primary):hover,
.k-dialog-buttongroup .k-button:not(.k-primary):active,
.k-dialog-buttongroup .k-button:not(.k-primary):focus {
  border-color: $button-gray-border-color-hovered;
}


