@import "variables";

::selection {
  background: $accent !important;
  color: $text-color !important;
}

.ap-fullheight {
  height: $full-percent;
}

.ap-transparent-background {
  background: none;
}

.ap-border-bottom-right {
  border-bottom: 3px solid $base-bg !important;
  border-right: 3px solid $base-bg !important;
}

.ap-form-right {
  margin-top: 25px;
  text-align: right;
}

.ap-icon {
  svg {
    height: $icon-size;
    width: $icon-size;

    polygon {
      fill: $icon-dark;
      stroke: $icon-dark;
    }

    path {
      fill: $icon-dark;
      stroke: $icon-dark;
    }

    rect {
      fill: $icon-dark;
      stroke: $icon-dark;
    }
  }
}

.ap-menu-info-panelbar .ap-icon {
  svg {
    height: 30px;
    width: 30px;
    vertical-align: middle;
  }
}

.ap-svg-grey {
  svg {
    path {
      fill: $icon-dark;
    }
  }
}

.ap-svg-red {
  svg {
    path {
      fill: $error;
    }
  }
}

.ap-status-grey {
  background-color: $unknown;
}

.ap-status-red {
  background-color: $error;
}

.ap-status-green {
  background-color: $success;
}

.ap-status-yellow {
  background-color: $warning;
}

.ap-status-grey,
.ap-status-green,
.ap-status-red,
.ap-status-yellow {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: auto;
}

input.k-checkbox + label.k-checkbox-label:after {
  background-color: $button-bg-color;
}

.ap-hidden-component {
  position: absolute;
  left: -1000px;
  top: 0;
}

.ap-toolbar-control {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
}

.ap-red {
  color: $error;
}

.ap-yellow {
  color: $warning;
}

.ap-green {
  color: $success;
}
