@import "../variables.scss";

div.ap-task-list-item-header {
  display: flex;
}

.ap-task-list-item {
  height: 55px;
  align-content: center;
  color: $base-bg;
  background-color: $blank;
  border-bottom-width: $small-border-size;
  border-bottom-color: $grid-header-border-color;
  border-bottom-style: solid;
}

ap-farm-job-list-item:nth-child(even) .ap-task-list-item {
  background-color: $light-bg-color;
}

div.farm-job-state-view,
div.ap-task-list-item-label,
div.ap-farm-job-list-item-time {
  padding: 5px;
}

div.ap-task-list-item-label {
  font-weight: bold;
}

div.ap-task-list-item-description {
  font-size: 13px;
  font-style: italic;
  padding-left: 2.2rem;
  padding-bottom: 5px;
}

.ap-black-text {
  color: $text-color;
}

div.farm-job-state-view {
  width: 25px;
}

div.ap-farm-job-list-item-time {
  width: 90px;
}
