div.histogramcontainer {
  position: relative;
  overflow: hidden;
}

div.empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: grey;
}

div.histogramcontainer,
div.empty-message {
  height: 100%;
  width: 100%;
}

div.diagram-controls {
  padding-top: 20px;
  padding-left: 20px;
}

div.x4chart {
  display: flex;
}

h3 {
  text-align: center;
}
