@import "variables";

$border-radius: $border-radius;
$primary: $accent;
$secondary: #f6f6f6;
$info: $info;
$success: $success;
$warning: $warning;
$error: $error;
$body-text: $base-text;
$body-bg: $base-bg;
$headings-text: $base-text;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: $base-text;
$component-bg: $base-bg;
$base-text: $base-text;
$base-bg: $base-bg;
$hovered-text: $text-color;
$hovered-bg: $accent;
$selected-text: $text-color;
$selected-bg: $accent;
$button-text: $text-color;
$button-bg: $accent;
$link-text: $accent;
$link-hover-text: #d6534a;
$series-a: $accent;
$series-b: $accent;
$series-c: $accent;
$series-d: $accent;
$series-e: $accent;
$series-f: $accent;

@import "~@progress/kendo-theme-default/dist/all.scss";
