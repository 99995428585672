$base-theme: Default;
$skin-name: acricon;
$swatch-name: Default;
$border-radius: 4px;
$accent: #b0cb1f;
$agriport-accent: $accent;
$accent-rgba-025: rgba(176, 203, 31, 0.25);
$accent-30perc: rgb(176 203 31 / 30%);
$accent-8perc: rgb(176 203 31 / 8%);
$unknown: #e0e0e0;
$info: #3e80ed;
$success: #5ec232;
$warning: #fdce3e;
$error: #980000;
$text-color: #2c3237; // #212529; changed due to APV49-1423
$bg-color: #fafafa; //background-color form #f5f6f8;
$base-text: #f5f6f8; // #ced4da;
$base-bg: #2c3237;
$base-bg-bright: #e8e8e8;
$grid-header-bg-color: #4d4f53; //#2c3237;
$grid-header-selected: #5a5a5a;
$grid-header-border-color: #E2E3E5;
$grid-header-details-bg-color: #c0c0c0;
$grid-header-details-selected: #c0ccc0;
$grid-header-details-filter-color: #e17d32;
$hovered-text: #b0cb1f;
$hovered-bg: #212529;
$selected-text: #b0cb1f;
$marked-cell: rgba(121, 121, 121, 0.32);
$selected-bg: #1a1d21;
$series-a: #b0cb1f;
$series-b: #b0cb1f;
$series-c: #b0cb1f;
$series-d: #b0cb1f;
$series-e: #b0cb1f;
$series-f: #b0cb1f;

$icon-dark: #76777b;
$blank: #fff;
$dark-bg-2: #1b1f22;
$light-bg-color: #f5f6f8;
$login-footer: #272b30;

$button-bg-color: #b0cb1f; // #b4da48;
$button-toggled-bg-color: #7ae81e;
$button-border-color: #72901d;
$hovered-button-bg-color: #72901d; //#acd635;
$hovered-button-light-bg: #e9ecef;
$hovered-button-border-color: #2f3b0c;
$button-line-height: 1.4;

$button-gray-bg-color: #D9E3F6;
$button-gray-border-color: #a9b3c6;
$button-gray-border-color-hovered: #8993a6;

$input-bg-color: $blank;
$input-border-color: #adb5bd;
$block-bg-color: rgba(173, 181, 189, 0.57);
$hovered-input-border-color: #2c3237; // changed due to APV49-1423
$input-line-height: 1.4;
$input-animation: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

$input-label-color: $text-color;
$input-label-font-weight: 700;
$input-label-font-size: 0.85rem;

$tab-label-font-weight: normal;
$tab-label-font-weight-active: bolder;

/* Fonts */
$main-font-family: "Titillium Web";
$main-font-size: 1rem;

/* Border */
$border-color-dark: #2c3237;
$border-color-light: #f5f6f8;
$small-border-size: 1px;
$small-border-dark: $small-border-size solid $border-color-dark;
$medium-border-size: 2px;
$medium-border-dark: $medium-border-size solid $border-color-dark;
$small-border-size-light: $small-border-size solid $border-color-light;
$medium-border-size-light: $medium-border-size solid $border-color-light;

$content-view-width: 50%;

/* Sizes */
// Global
$full-percent: 100%;

$main-line-height: 1.2;

// Header
$header-height: 38px;
$header-padding: 7.75px;

// Form Content
$form-content-padding-height: 30px;
$form-content-padding-width: 10%;

// Form Actions
$form-actions-height: 36px;
$form-actions-padding: 10px;

$icon-size: 32px;

// Footer Standalone
$footer-height: 55px;

// Sidebar
$sidebar-open-width: 233px;

// Logo
$logo-height: 45px;
$logo-width: 131px;
$logo-top: 15px;
$logo-left: 30px;

// Login
$login-content-width: 768px;

/* Z Position */
$z-low: 0;
$z-mid: 2;
$z-high: 4;
$z-force: 6;
$z-tooltip: 8;
$z-maximum: 10;

/* Kendo */
// Form
$k-form-font-size: 1rem;

$ap-tabstrip-arrow-button-size: 36px;
$ap-tabstrip-arrow-button-margin: 10px;
$ap-tabstrip-arrow-width: 11.5px;
$ap-tabstrip-arrow-height: 20px;
$ap-tabstrip-bottom-border-width: 6px;

// Crop Rotation Table
$cr-small-row-height: 25px;
$cr-border: $small-border-size solid rgba(0, 0, 0, 0);
$cr-line-height: 17px;
$cr-cell-padding-top: 6px;
$cr-cell-padding-right: 8px;
$cr-header-height: $cr-small-row-height*4;
$cr-data-height: calc(100% - 120px);
$cr-header-font-size: 14px;
$cr-header-font-weight: 400;
$cr-header-detail-height: $cr-small-row-height*3;
$cr-header-field-width: 428px;
$cr-data-height: calc(100% - 164px);
$cr-data-font-size: 14px;
$cr-data-font-weight: 400;
$cr-data-child-width: 181px;
$cr-data-child-placeholder-width: 51px;

$ap-button-height: 32px;
$ap-button-width: 32px;
$ap-button-busy-cursor: wait;
$ap-button-free-cursor: pointer;

//SVG
$svg-fill: #76777b;

$ap-option-size: 20px;
$ap-option-padding: 24px;

$ap-button-image-size: 20px;

$ap-grid-line-height: 25px;

:root {
  --accent-color: #{$accent};
  --error-color: #{$error};
  --warning-color: #{$warning};
  --unknown-color: #{$unknown};
}
