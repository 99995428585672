@import "../variables";

/* Values */
.ap-force-border-left {
  border-left: $cr-border !important;
}

.ap-cr-cell-detail {
  color: $base-text;
  border-bottom: $cr-border;
  border-left: $cr-border;
  line-height: $cr-line-height;
  font-size: $cr-header-font-size;
  font-weight: $cr-header-font-weight;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
}

.ap-cr-cell-marked {
  border-left: 3px solid rgba(0, 0, 0, 0.00); // removed border by setting alpha to 0 => avoids column width re-calculation;
  border-right: 3px solid rgba(0, 0, 0, 0.00);

  ap-crop-rotation-detail-header-big > div {
    border-left: 0;
  }
}

div.ap-cr-back-row > label.k-checkbox-label {
  margin-left: 19px;
  margin-top: 6px;
}

div.invalid-info {
  position: absolute;
  margin-left: 50px;
}

.ap-cr-disable-cell {
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(125, 129, 133, 0.5) 0px, rgba(125, 129, 133, 0.5) 16px);
  cursor: not-allowed;
  z-index: 1;
  width: 200%;
  height: $cr-small-row-height*3;
  margin-bottom: -$cr-small-row-height*3;
  position: relative;

  -webkit-animation-name: MOVE-BG;
  -webkit-animation-duration: .6s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

  animation-name: MOVE-BG;
  animation-duration: .6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(-46px);
  }
  to {
    -webkit-transform: translateX(0);
  }
}

@keyframes MOVE-BG {
  from {
    transform: translateX(-46px);
  }
  to {
    transform: translateX(0);
  }
}

.ap-cr-cell > kendo-combobox,
.ap-cr-front-row > kendo-combobox,
.ap-cr-inline > kendo-combobox {
  width: 100%;
}

.ap-cr-second-crop-selection {
  span.k-dropdown-wrap {
    kendo-searchbar > input {
      height: $cr-small-row-height !important;
    }

    span.k-icon.k-clear-value {
      top: 4px !important;
    }
  }
}

.ap-cr-cell > kendo-combobox > span,
.ap-cr-front-row > kendo-combobox > span {
  border-color: transparent;
}

.ap-cr-cell > kendo-combobox > span > span.k-select,
.ap-cr-front-row > kendo-combobox > span > span.k-select {
  color: transparent;
  background-color: transparent !important;
  background-image: none !important;
}

.ap-cr-cell > kendo-combobox > span.k-dropdown-wrap,
.ap-cr-front-row > kendo-combobox > span.k-dropdown-wrap {
  border: none;
  background-color: rgba(176, 203, 31, 0.01);

  kendo-searchbar > input {
    height: $cr-small-row-height*2;
    font-size: 16px;
    font-weight: bold;
  }

  span.k-icon.k-clear-value {
    top: 20px;
  }
}

.ap-cr-second-crop-font > span.k-dropdown-wrap {
  kendo-searchbar > input {
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: italic !important;
  }
}

.ap-cr-combobox-popup li {
  font-size: 16px;
}

.ap-cr-cell > kendo-combobox > span.k-dropdown-wrap:hover,
.ap-cr-cell > kendo-combobox > span.k-dropdown-wrap:focus,
.ap-cr-front-row > kendo-combobox > span.k-dropdown-wrap:hover,
.ap-cr-front-row > kendo-combobox > span.k-dropdown-wrap:focus {
  border: none;

  span.k-select {
    color: $grid-header-bg-color;
    background-color: transparent !important;
    background-image: none !important;
  }
}

.ap-cr-cell-value-font-bold {
  font-size: $cr-data-font-size !important;
  font-weight: bold !important;
}

.ap-cr-cell-value-font {
  font-size: $cr-data-font-size !important;
  font-weight: $cr-data-font-weight !important;
}

.ap-cr-yield-input {
  font-size: $cr-data-font-size !important;
  font-weight: $cr-data-font-weight;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
}

.ap-cr-cell > button.ap-cr-add-button,
.ap-cr-cell > button.ap-cr-add-button:hover,
.ap-cr-cell > button.ap-cr-add-button:focus,
.ap-cr-inline > button.ap-cr-add-button,
.ap-cr-inline > button.ap-cr-add-button:hover,
.ap-cr-inline > button.ap-cr-add-button:focus,
.ap-cr-front-row > button.ap-cr-add-button,
.ap-cr-front-row > button.ap-cr-add-button:hover,
.ap-cr-front-row > button.ap-cr-add-button:focus {
  color: $grid-header-bg-color;
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;
  font-size: $cr-data-font-size !important;
  font-weight: $cr-data-font-weight;
  box-shadow: none !important;
}

button.ap-cr-extend-button,
button.ap-cr-extend-button:hover,
button.ap-cr-extend-button:focus {
  color: $grid-header-bg-color;
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;
  font-size: $cr-data-font-size !important;
  font-weight: $cr-data-font-weight;
  box-shadow: none !important;
  width: 30px;
}

.ap-cr-crop-row > .ap-cr-front-row > button.ap-cr-add-button {
  margin-left: calc(87px - 1.2rem);
  color: rgba(44, 50, 55, 0.5);
}

.ap-cr-crop-row > .ap-cr-front-row > button.ap-cr-add-button:hover,
.ap-cr-crop-row > .ap-cr-front-row > button.ap-cr-add-button:focus {
  color: rgba(44, 50, 55, 1);
}

.ap-cr-crop-row-big > .ap-cr-front-row > button.ap-cr-add-button {
  margin-top: calc(45px - 0.8rem);
  margin-left: calc(87px - 1.2rem);
  color: rgba(44, 50, 55, 0.5);
}

.ap-cr-crop-row-big > .ap-cr-front-row > button.ap-cr-add-button:hover,
.ap-cr-crop-row-big > .ap-cr-front-row > button.ap-cr-add-button:focus {
  color: rgba(44, 50, 55, 1);
}

.ap-cr-crop-row-big > .ap-cr-front-row > button.ap-cr-add-button > .k-icon {
  font-size: 1.2rem;
}

.ap-cr-front-row {
  width: 100%;

  kendo-combobox {
    width: 100%;
  }
}

.ap-cr-back-row {
  min-width: 50px;
  max-width: 50px;

  label > input,
  input {
    background-color: rgba(176, 203, 31, 0.01);
  }

  .k-textbox {
    width: 48px;
    height: 25px;
  }

  label > .k-textbox {
    width: 48px;
  }

  label > input[type=checkbox] {
    border: 0.0625rem solid #adb5bd;
  }

  label > input[type=checkbox]:hover,
  label > input[type=checkbox]:active,
  label > input[type=checkbox]:focus {
    border: 0.0625rem solid #495057;
  }
}
