@import "../variables";

.k-upload .k-dropzone {
  border: $small-border-size dashed gray;
}

.k-upload .k-dropzone em {
  visibility: visible;
}

.k-upload .k-dropzone-active {
  border: 3px dashed gray;
  height: 500px;

  .k-upload-button {
    visibility: hidden;
  }

  .k-action-buttons {
    visibility: hidden;
  }

  .k-upload-files {
    visibility: hidden;
  }
}

.k-action-buttons .k-primary {
  color: $text-color;
}

.k-upload .k-dropzone-active.k-dropzone-hovered {
  border: 3px dashed $success;
  height: 500px;
}

.k-upload .k-upload-button {
  position: relative;
}

div.k-dropzone-hint {
  position: relative;
}

.k-action-buttons .k-button + .k-button {
  border-left-width: $small-border-size;
}

.k-upload .k-upload-files {
  overflow-y: auto;
  max-height: 500px;
}

.k-upload .k-clear-selected, .k-upload .k-upload-selected {
  display: inline-block;
  width: 50%;
  margin: 0;
  line-height: $main-line-height;
  padding: 1em 0;
}
