@import "../variables";

.k-editor-content {
  background-color: $bg-color;
  color: $text-color;
}

.k-editor .k-dialog {
  width: 60% !important;
  height: 70% !important;
  display: flex;
  flex-direction: column;
}

.k-editor .k-content {
  display: block !important;
}

.k-editor .k-textarea,
.k-editor .k-textbox {
  background-color: $bg-color;
  color: $text-color;
}

.k-editor .k-dropdown .k-dropdown-wrap,
.k-editor .k-dropdowntree .k-dropdown-wrap,
.k-editor .k-colorpicker,
.k-editor .k-colorpicker .k-picker-wrap .k-select,
.k-editor .k-colorpicker .k-picker-wrap {
  background-color: $accent;
}

kendo-editor.k-readonly {
  cursor: initial;
  opacity: 1;
  filter: initial;
  pointer-events: initial;
  box-shadow: initial;
}

.k-editor .k-iframe {
  line-height: $main-line-height;
  font-size: $main-font-size;
  font-family: $main-font-family;
  background-color: $bg-color;
  color: $text-color;
}

ap-help .k-upload-files .k-file-name,
ap-help .k-upload .k-file-progress .k-file-name,
ap-help .k-file-name {
  color: $bg-color;
}

ap-help .k-dropdown,
ap-help .k-dropdowntree,
ap-help .k-menu-button,
ap-help .k-dropdown-button {
  height: calc(1.4em + 8px + 2px);
}

ap-help button[kendoButton] {
  font-size: 14px;
}

ap-modal-dialog .k-upload .k-dropzone {
  background-color: transparent;
}

ap-modal-dialog .k-upload .k-upload-files,
ap-modal-dialog .k-upload .k-dropzone {
  width: 420px;
}

ap-modal-dialog .k-upload .k-upload-button {
  margin-right: 10px;
  width: 40px;
}
