@import "../variables";

.ap-cr-column {
  border-left: $cr-border;
  padding: $cr-cell-padding-top $cr-cell-padding-right;
  font-size: $cr-data-font-size;
  font-weight: $cr-data-font-weight;
  height: 78px;
  display: flex;
  align-items: center;
}

.ap-cr-value-column-wrapper {
  width: 100%;
  display: flex;
}

.ap-cr-value-column {
  border-left: $cr-border;
  min-width: 150px;
  max-width: 1000px;
  overflow: hidden;
}

.ap-cr-child-list {
  line-height: 75px;
}

.ap-cr-row-data {
  height: $cr-small-row-height*3;
}

.ap-cr-placeholder {
  margin-left: $cr-data-child-placeholder-width;
  max-width: $cr-data-child-width;
  min-width: $cr-data-child-width;
  border-left: none;
}

.ap-cr-row-main {
  border-top: $small-border-size solid black;
}

.ap-cr-row-main:first-child {
  border-top: none;
}

.ap-cr-row-main:last-child {
  border-bottom: $small-border-size solid black;
}

.ap-cr-history-info {
  font-weight: bold;
  font-size: 25px;
  height: 100%;
  min-width: 150px;
  max-width: 1000px;
  align-items: flex-start;
  display: flex;
  padding-top: 15px;
  padding-left: 10px;
}

.ap-cr-value-filler {
  width: 31px;
}
