@import "../variables";

.k-panelbar:not(.ap-layer-group):not(.ap-layer-control) > .k-item > .k-link.k-state-selected,
.k-panelbar:not(.ap-layer-group):not(.ap-layer-control) > .k-panelbar-header > .k-link.k-state-selected {
  background-color: $base-bg;
  color: $accent !important;
}

.k-panelbar .k-group > .k-item > .k-link.k-state-selected {
  color: $accent;
}

.ap-menu-wrapper {
  height: calc(99vh - #{$logo-height} - #{$logo-top*2} + #{$small-border-size});
}

.ap-menu {
  height: calc(99vh - #{$logo-height} - #{$logo-top*2} - 45px);
  display: flex;
  flex-flow: column;
  flex-basis: 0;
  flex-grow: 1;
  align-items: stretch;
}

/* Styling of collapsed menu*/
.ap-menu-collapsed,
.ap-menu-collapsed .ap-sidebar-toggle {
  width: $header-height;
}

.ap-menu-collapsed .k-panelbar .k-group {
  opacity: 0;
  z-index: $z-force;
  width: 187.5px;
  box-shadow: 13px 11px 20px 0 rgba(0, 0, 0, 0.75);
}

.ap-menu-collapsed .ap-menu-main-panelbar .k-group {
  position: fixed;
  left: $header-height;
}

.ap-menu-collapsed .ap-menu-info-panelbar > kendo-panelbar-item > div {
  position: relative;
}

.ap-menu-collapsed .ap-menu-info-panelbar > kendo-panelbar-item > div > div {
  position: absolute;
  bottom: 32px;
  left: 32.5px;
}

.ap-menu-collapsed-hovered .k-panelbar .k-group {
  opacity: 1;
  animation-duration: 300ms;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    //margin-left: 100%;
    opacity: 0;
    //height: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.75);
  }

  to {
    //margin-left: 0%;
    opacity: 1;
    //height: 30px;
    box-shadow: 13px 11px 20px 0 rgba(0, 0, 0, 0.75);
  }
}

.ap-menu-collapsed .k-panelbar > .k-item.k-state-expanded > .k-link,
.ap-menu-collapsed .k-panelbar > .k-item.k-state-expanded > .k-link:hover {
  width: 220px;
  overflow: visible;
}


.ap-menu-collapsed .k-panelbar > .k-item:not(.k-state-expanded) {
  width: $header-height;
  overflow: hidden;
}

.ap-menu-sub {
  border-bottom: $small-border-dark;
}

.ap-menu-main:hover,
.ap-menu-info:hover {
  background-color: $hovered-bg;
}

.ap-menu-main, .ap-menu-sub {
  width: $full-percent;
  height: $header-height;
  display: flex;
  align-items: center;
}

.ap-menu-info {
  font-size: 12px;
  margin-bottom: -5px;
}

.ap-small-menu {
  .ap-menu-sub {
    width: auto;
  }
}

.ap-menu-sub {
  padding: 0 0 0 20px;
  background-color: $hovered-bg;
  justify-content: flex-start;
}

.ap-menu-main:hover, .ap-menu-sub:hover {
  cursor: pointer;
  color: $accent !important;
}

.ap-menu-info:hover {
  opacity: 1;
}

.ap-menu > kendo-panelbar {
  background-color: transparent;
  font-size: 15px;
  border: none;
}

.ap-menu > .k-panelbar > .k-item > .k-link,
.ap-menu > .k-panelbar > .k-item > .k-link > span.k-link {
  color: $base-text;
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
}

.k-panel.k-group > .k-item > .k-link {
  background-color: $dark-bg-2;
  padding: 0;
  transition: none;
}

.ap-menu > .k-panelbar > .k-item > .k-link:hover {
  cursor: pointer;
  background-color: $hovered-bg !important;
}

.ap-menu-title-selected,
.ap-menu-title-selected .ap-menu-main,
.ap-menu-main:hover, .ap-menu-info:hover {
  color: $accent;

  ap-icon-selector {
    .ap-icon {
      svg {
        polygon.hoverFill {
          fill: $accent !important;
          stroke: $accent !important;
        }

        path.hoverFill {
          fill: $accent !important;
          stroke: $accent !important;
        }

        rect.hoverFill {
          fill: $accent !important;
          stroke: $accent !important;
        }
      }
    }
  }
}

.ap-menu-title {
  padding-left: 6px;
}

.ap-menu-info .ap-menu-title {
  padding-left: 8px;
}

.ap-menu-title-group {
  padding-left: 6px;
  text-decoration: underline;
}

.ap-menu > .k-panelbar .k-group > .k-item > .k-link:hover {
  background-color: $selected-bg;
}

.ap-menu > .k-panelbar > .k-item > .k-link:focus {
  color: $base-text;
}

.ap-menu > .k-panelbar > .k-item > .k-link:hover > .k-icon:nth-child(1) {
  color: $accent;
}

.ap-menu > .k-panelbar > .k-item > .k-link .k-icon:nth-child(1) {
  font-size: 25px;
  padding-right: 5px;
}

.ap-menu > .k-panelbar > .k-item > .k-link .k-icon:nth-child(2) {
  display: none;
}

.ap-menu > .k-panelbar > .k-item + .k-item {
  border: none;
}

.ap-menu > .k-panelbar .k-group > .k-item > .k-link.k-state-selected {
  font-weight: bold;
}

.ap-menu > .k-panelbar .k-group > .k-item > .k-link.k-state-selected:hover {
  background-color: $selected-bg;
}

.k-panelbar .k-group {
  color: $base-text;
}

.k-panelbar .k-group > .k-item.k-state-selected {
  color: #b0cb1f;
}

.ap-menu > .k-panelbar > .k-item:not(.ap-menu-title-selected) > .k-link:not(.k-state-selected) {
  background-color: $base-bg;
  color: $base-text;
}

.k-link.k-header.k-state-selected > .ap-menu-main svg path.hoverFill {
  fill: $accent !important;
  stroke: $accent !important;
}

.ap-layer-control {
  min-width: 178px;
  max-width: 250px;
}

.ap-layer-control-layer-group > .k-panelbar > .k-item > .k-link.k-state-selected,
.ap-layer-control-layer-group > .k-panelbar .k-group > .k-item > .k-link.k-state-selected {
  background: $bg-color !important;
  color: $text-color !important;
  border: none !important;
}

.ap-layer-control-layer-group {
  padding: 0 !important;
}

.ap-layer-control-layer-group,
.ap-layer-control-layer-item > span.k-state-selected,
.ap-layer-control-layer-item > span.k-state-focused,
.ap-layer-control-layer-group > span.k-state-selected,
.ap-layer-control-layer-group > span.k-state-focused,
.ap-layer-control-layer-group > .k-link,
.ap-layer-control-layer-group > .k-link > div {
  background: $bg-color !important;
  color: $text-color !important;
  border: none !important;
}

.ap-layer-control-layer-item > span.k-state-focused,
.ap-layer-control-layer-group > span.k-state-focused {
  box-shadow: none !important;
}

.ap-layer-control-layer-group > .k-link > .k-icon {
  color: $text-color !important;
}

.ap-layer-control-layer-item {
  margin-left: 25px;
  color: $text-color !important;
}

.ap-layer-control-layer-item > span {
  background: $bg-color !important;
}

.ap-layer-group > .ap-layer-control-layer-group > div > div > .ap-layer-control-layer-item {
  margin-left: 0;
}

.ap-menu > .k-panelbar .k-group > .k-item > .k-link.k-state-selected:hover {
  background-color: $accent;
}

.ap-menu > .k-panelbar .k-group > .k-item > .k-link:hover {
  color: $accent;
}

.ap-menu > .k-panelbar .k-group > .k-item > .k-link {
  cursor: pointer;
}

.k-button-flat::after, .k-button.k-flat::after, .k-button.k-bare::after,
.k-list .k-item:focus,
.k-list .k-item.k-state-focused,
.k-list-optionlabel:focus,
.k-list-optionlabel.k-state-focused,
.k-panelbar > .k-item > .k-link:focus,
.k-panelbar > .k-item > .k-link.k-state-focus,
.k-panelbar > .k-item > .k-link.k-state-focused,
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-state-focus,
.k-panelbar > .k-panelbar-header > .k-link.k-state-focused {
  box-shadow: none;
}
