@import "../variables";

.k-popup > .k-flatcolorpicker:only-child {
  width: auto
}

.k-colorpicker .k-picker-wrap {
  background-color: $bg-color;
  background-image: none;
}

.k-colorpicker .k-picker-wrap .k-select {
  background-color: $base-bg;
}

.k-grid-container .k-widget.k-colorpicker {
  display: inline;
}

.k-grid-container .k-picker-wrap {
  width: fit-content;
}

.k-colorpicker-popup .k-i-arrows-kpi:hover,
.k-colorpicker-popup .k-colorgradient-toggle-mode:hover {
  color: $accent;
}

.k-colorpicker-popup .k-button:focus {
  color: $input-bg-color;
  border: 0;
  box-shadow: none !important;
}

.k-colorpicker-popup .k-textbox {
  color: $base-bg;
  background: $input-bg-color;
}

.k-colorgradient-inputs .k-colorgradient-input-label,
.k-colorpicker .k-picker-wrap .k-select {
  color: $bg-color;
}

.k-colorgradient-inputs .k-vstack {
  align-items: center;
}

.k-colorgradient-inputs .k-button-flat::after,
.k-colorgradient-inputs .k-button.k-flat::after,
.k-colorgradient-inputs .k-button.k-bare::after {
  box-shadow: none;
}
