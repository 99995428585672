@import "../variables";

.k-button {
  color: $base-bg;
  font-size: inherit;
  background-color: $accent;
  border-color: $button-border-color;
  padding: 0.375rem 0.75rem;
}

.k-button:hover, .k-button:active, .k-button:focus {
  border-color: $hovered-button-border-color;
  background-color: $accent;
  color: $base-bg;
}

.k-button.ap-state-active {
  background-color: $button-toggled-bg-color;
}

.k-button.dark-bg, .k-dropdown-button.dark-bg > button {
  color: $base-text;
  background-color: $base-bg;
  border-color: $base-text;
}

.k-button:hover.dark-bg, .k-button:active.dark-bg, .k-button:focus.dark-bg,
.k-dropdown-button.dark-bg > button:hover, .k-dropdown-button.dark-bg > button:active, .k-dropdown-button.dark-bg > button:focus {
  color: $accent;
  background-color: $base-bg;
  border-color: $accent;
}

.k-button .k-image {
  min-width: $ap-button-image-size;
  min-height: $ap-button-image-size;
}

.k-primary {
  color: $base-bg;
  background-color: $accent;
  border: $small-border-size solid $button-border-color;
  line-height: $button-line-height;
  padding: 0.375rem 0.75rem;
}

.k-primary:hover {
  color: $base-bg;
  background-color: $accent;
  border: $small-border-size solid $hovered-button-border-color;
}

.k-button .k-icon, .k-button .k-image, .k-button .k-sprite {
  font-size: 1rem;
}

.k-button-outline, .k-button.k-outline {
  color: $base-text;
}

.k-button-outline:hover, .k-button.k-outline:hover, .k-button-outline.k-state-hover, .k-state-hover.k-button.k-outline {
  color: $accent;
  background-color: $base-bg;
}

.k-button-outline:focus, .k-button.k-outline:focus, .k-button-outline.k-state-focus, .k-state-focus.k-button.k-outline, .k-button-outline.k-state-focused, .k-state-focused.k-button.k-outline {
  box-shadow: 0 0 0 2px $accent-8perc;
}

/* Agriport Customs */
.button-important {
  text-transform: uppercase;
  font-weight: 700;
}

div.ap-toolbar-control > button.k-button > span.k-icon,
div.ap-toolbar-control > kendo-dropdownbutton > button.k-button > span.k-icon {
  font-size: 1.5rem;
}

.badge {
  font-size: 1rem;
}

.userprofile-button > button,
button.notification-button {
  border: none !important;
}

.k-button-primary, .k-button.k-primary {
  border-color: $button-border-color;
  color: $base-bg;
}

.k-button-primary:hover, .k-button.k-primary:hover, .k-button-primary.k-state-hover, .k-state-hover.k-button.k-primary {
  border-color: $hovered-button-border-color;
  color: $base-bg;
}
