@import "../variables";

.ap-error {
  color: $error !important;
  font-size: $input-label-font-size;
  line-height: 1rem;
  margin-bottom: -1rem;
}

.ap-error:empty {
  margin-bottom: 0px;
}
