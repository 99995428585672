@import "../variables";

kendo-chart.css-chart-style text {
  fill: rgb(0, 0, 0);
}

.k-chart-surface > svg g > path[fill="rgb(44, 50, 55)"] {
  fill: transparent;
}

.k-chart {
  border-bottom-color: black;
  border-bottom-width: $small-border-size;
}

ap-dynforms .k-chart {
  border-bottom: none;
}

.tooltip {
  color: $accent;
}
