@import "variables";

.ap-form {
  display: grid;
  width: 100%;
  margin: auto;
  padding: 0;
  height: calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
}

.ap-form-standalone {
  height: 100%;
  display: grid;
  width: 98%;
  padding: 1vh 1vw;
  margin: auto;
  grid-template-rows: auto 40px;
}

.ap-form-content-standalone {
  height: calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
}

.ap-form-element-border {
  border: 0.0625rem solid $input-border-color;
}

.ap-form-element-border:hover {
  border-color: $hovered-input-border-color;
}

.ap-dynform-grid-item kendo-grid-list {
  border: 0.0625rem solid #adb5bd;
  border-radius: 0.25rem;
  height: 120px;
}

.ap-dynform-grid-item.error kendo-grid-list {
  border-color: $error;
}

.ap-dynform-grid-item.error .k-grid-content {
  overflow: hidden;
}

.ap-form-element-error-border {
  border-color: $error
}

.ap-form-content {
  background-color: $blank;
  padding: $form-content-padding-height $form-content-padding-width;
  grid-column-start: 1;
  overflow-y: auto;
  height: calc(#{$full-percent} - #{$form-content-padding-height} * 2 - #{$header-height} - #{$header-padding} * 2 - #{$form-actions-height} - #{$form-actions-padding} * 2);
}

ap-pdf-viewer .ap-form-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  height: calc(#{$full-percent} - #{$form-content-padding-height} - #{$header-height} - #{$header-padding} * 2 - #{$form-actions-height} - #{$form-actions-padding} * 2);
}

ap-pdf-viewer .loadingIcon {
  visibility: hidden;
  display: none;
}

ap-dyncomponent .ap-dynforms-action-wrapper {
  position: absolute;
  bottom: 0;
  column-span: all;
  width: 100%;
}

ap-breadcrumbs-steper {
  display: flex;
  height: 35px;
}

.ap-dynform-fieldset-label {
  padding-bottom: 20px;
}

.ap-dynform-control {
  border-color: transparent;
}

.ap-dynform-control,
.ap-dynform-control input,
.ap-dynform-control span,
.ap-dynform-control div {
  width: 100%;
  font-size: 1rem;
}

textarea.ap-dynform-control {
  resize: none;
}

textarea.ap-dynform-control,
.ap-dynform-control .k-multiselect-wrap,
.ap-dynform-control .k-numeric-wrap,
.ap-dynform-control .k-dropdown-wrap,
.ap-dynform-control .k-multiselect-wrap,
.ap-dynform-control .k-picker-wrap {
  border: 0.0625rem solid $input-border-color;
  border-radius: 0.25rem;
}

textarea.ap-dynform-control:hover,
.ap-dynform-control .k-multiselect-wrap:hover,
.ap-dynform-control .k-numeric-wrap:hover,
.ap-dynform-control .k-dropdown-wrap:hover,
.ap-dynform-control .k-multiselect-wrap:hover,
.ap-dynform-control .k-picker-wrap:hover {
  border: 0.0625rem solid $hovered-input-border-color;
}

.ap-dynform-fieldset {
  display: grid;
  grid-auto-columns: minmax(150px, 550px);
  row-gap: 15px;
}

.ap-dynform-fieldset-max {
  display: grid;
  grid-auto-columns: minmax(200px, 100%);
  row-gap: 15px;
  width: 100%;
}

.ap-dynform-element {
  align-self: center;
}

.ap-form-block {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.ap-form-footer-standalone {
  height: $footer-height;
  background: $base-bg;
  color: $base-text;
  z-index: $z-mid;
  border-width: $small-border-size;
  border-style: solid;
}

.ap-form-content ap-grid > div {
  padding-bottom: 10px;
}

.ap-form-actions {
  background-color: $base-bg;
  height: $form-actions-height;
  padding: $form-actions-padding;
  grid-column-start: 1;
}

.ap-form-button-left {
  float: left;
}

.ap-form-button-right {
  float: right;
}

form.dark-bg,
div.ap-form-content.dark-bg,
legend.dark-bg {
  background-color: $base-bg;
  color: $base-text !important;
}

input.dark-bg,
kendo-multiselect.dark-bg > div.k-multiselect-wrap,
kendo-combobox.dark-bg > div.k-multiselect-wrap,
kendo-datepicker.dark-bg {
  background-color: $base-bg;
  color: $base-text !important;
}

.k-disabled .k-select {
  // Still show the selector dropDown if the control is disabled (?!)
  // display: none;
}

div.no-action {
  height: calc(#{$full-percent} - #{$form-content-padding-height} * 2 - #{$header-height} - #{$header-padding} * 2);
}

ap-pdf-viewer div.no-action {
  height: calc(#{$full-percent} - #{$form-content-padding-height} - #{$header-height} - #{$header-padding} * 2);
}

div.no-header {
  height: calc(#{$full-percent} - #{$form-content-padding-height} * 2 - #{$form-actions-height} - #{$form-actions-padding} * 2);
}

ap-pdf-viewer div.no-header {
  height: calc(#{$full-percent} - #{$form-content-padding-height} - #{$form-actions-height} - #{$form-actions-padding} * 2);
}

div.no-action.no-header {
  height: calc(#{$full-percent} - #{$form-content-padding-height} * 2);
}

div.ap-form-content.modal-form-full-width {
  width: calc(100% - 15px * 2);
}

ap-modal-dialog div.no-action.no-header {
  height: min-content;
  overflow-x: hidden;
  display: inline-table;
}

ap-modal-dialog fieldset {
  //display: contents;
}

ap-modal-dialog .ap-form-content {
  padding: 30px 15px;
}

ap-pdf-viewer div.no-action.no-header {
  height: calc(#{$full-percent} - #{$form-content-padding-height});
}

.dark-bg ap-responsive-text span {
  color: $base-text;
}

.dark-bg button ap-responsive-text span {
  color: $text-color;
}

.dark-bg .k-tabstrip .k-item.k-state-active {
  background-color: $dark-bg-2;
}

.dark-bg .k-tabstrip-items .k-item {
  border-bottom-color: $selected-text;
  border-bottom-width: 0.2rem;
}

.k-tabstrip-items .k-item {
  border-color: rgba(0, 0, 0, 0.08);
}

ap-modal-dialog ap-dynforms form > div,
ap-modal-dialog ap-dynforms fieldset > div {
  width: min-content;
}

ap-modal-dialog ap-dynforms ap-dynforms-element,
ap-modal-dialog ap-dynforms ap-dynforms-element div {
  width: 100%;
}

button#ap-modal-dialog-cancel-button:active,
button#ap-modal-dialog-cancel-button:hover {
  background-color: $marked-cell;
  background-image: linear-gradient($marked-cell, $button-bg-color);
  color: $text-color;
}

.k-pager-wrap div.ap-form-content.no-header.no-action {
  overflow-y: hidden;
}

.ap-form-action-underline {
  padding-bottom: 20px;
  border-bottom: $small-border-size solid $input-border-color;
}

ap-dynforms kendo-dropdownlist {
  width: 12.4rem !important;
}

ap-dynforms .k-loading-image {
  background-color: white;
  opacity: 0.4;
}

.ap-dynform-element-center {
  text-align: center;
}

.ap-icon-medium {
  display: flex;
  width: 20px;
  height: 20px;
}
