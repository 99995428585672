@import "../variables";

.ap-wizard {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #2c3237;

  ul {
    margin-left: 40%;
  }

  div {
    height: 95%;
  }
}

.ap-wizard-div {
  background: lightgray;
}

.ap-wizard-kendo-tabstrip-tab {
  background: lightgray;
  height: 100%;
}

.ap-wizard-arrow {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
  line-height: 2.5em;
  padding-left: 1em;
  padding-right: 2em;
  background: white;
  color: black;

  :after {
    // triangle hover color
    border-left: 20px solid white;
  }
}

/*.ap-wizard-arrow:after {
  // the triangle
  content: "";
  position: absolute;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  height: 0px;
  width: 0px;
  margin-right: -20px;
  right: 0;
}*/

.ap-wizard-arrow :hover, .ap-wizard-arrow :active {
  background: yellow;
  color: black;

  :after {
    // triangle hover
    border-left: 20px solid yellow;
  }
}
