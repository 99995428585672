@import "../variables";

.k-combobox .k-select {
  color: $base-text;
  background-color: $base-bg;
}

.k-combobox > :hover .k-select, .k-combobox .k-state-hover .k-select {
  color: $accent;
  background-color: $base-bg;
}

.k-combobox .k-clear-value {
  display: none;
}

.k-combobox:hover .k-clear-value, .k-combobox.k-state-hover .k-clear-value, .k-combobox.k-state-focused .k-clear-value, .k-combobox.k-state-focus .k-clear-value, .k-combobox .k-dropdown-wrap.k-state-hover .k-clear-value, .k-combobox .k-dropdown-wrap.k-state-focused .k-clear-value {
  display: none;
}

// fix Dropdown List focus Color
.k-dropdown .k-dropdown-wrap .k-dropdown-list .k-nodata .k-popup .k-numeric-wrap {
  color: $base-text;
}

.k-animation-container > .k-popup {
  color: $base-text;
}

.k-popup .k-list-container .k-reset {
  color: $base-text;
}

.k-list-container .k-nodata {
  color: $base-text;
}

/* Kendo Dropdown normal Style */

.k-dropdown .k-dropdown-wrap {
  border: $small-border-size solid $input-border-color;
  background-color: $base-text;
}

.k-dropdown .k-dropdown-wrap:hover {
  border-color: $base-bg;
}

.k-dropdown .k-dropdown-wrap:hover .k-input {
  color: $base-bg;
}

.k-dropdown .k-input {
  color: $base-bg;
  height: 29px;
}

.k-dropdown .k-select {
  margin-top: $small-border-size;
  margin-right: $small-border-size;
  height: 30px;
  color: $base-bg;
}

.k-dropdown .k-dropdown-wrap:hover .k-input {
  color: $base-bg;
}

.k-dropdown .k-dropdown-wrap:hover {
  border-color: $base-bg !important;
}

/* Kendo Dropdown Dark Background */

.k-dropdown.dark-bg .k-dropdown-wrap {
  border: $small-border-size solid $base-text;
  background-color: $base-bg;
}

.k-dropdown.dark-bg .k-dropdown-wrap:hover {
  border-color: $accent;
}

.k-dropdown.dark-bg .k-dropdown-wrap:hover .k-input {
  color: $accent;
}

.k-dropdown.dark-bg .k-input {
  color: $base-text;
}

.k-dropdown.dark-bg .k-select {
  margin-top: $small-border-size;
  margin-right: $small-border-size;
  height: 29px;
  color: $base-text;
}

.k-dropdown.dark-bg .k-dropdown-wrap:hover .k-input {
  color: $accent;
}

.k-dropdown.dark-bg .k-dropdown-wrap:hover .k-select {
  color: $accent;
}

.k-dropdown.dark-bg .k-dropdown-wrap:hover {
  border-color: $accent !important;
}

/* Kendo Combobox normal Style */

.k-combobox .k-dropdown-wrap {
  border: $small-border-size solid $input-border-color;
  background-color: $base-text;
}

.k-combobox .k-dropdown-wrap:hover {
  border-color: $base-bg;
}

.k-combobox .k-dropdown-wrap:hover .k-select {
  border-color: $base-bg;
  background-color: $base-bg;
  color: $base-text;
}

.k-combobox .k-dropdown-wrap:hover .k-input {
  color: $base-bg;
}

.k-combobox .k-input {
  color: $base-bg;
}

.k-combobox .k-select {
  background-color: $base-bg;
  color: $base-text;
}

.k-combobox .k-dropdown-wrap:hover .k-input {
  color: $base-bg;
}

.k-combobox .k-dropdown-wrap:hover {
  border-color: $base-bg !important;
}

/* Kendo Combobox Dark Background */

.k-combobox.dark-bg .k-dropdown-wrap {
  border: $small-border-size solid $base-text;
  background-color: $base-bg;
}

.k-combobox.dark-bg .k-dropdown-wrap:hover {
  border-color: $accent;
}

.k-combobox.dark-bg .k-dropdown-wrap:hover .k-input {
  color: $accent;
}

.k-combobox.dark-bg .k-input {
  color: $base-text;
}

.k-combobox.dark-bg .k-dropdown-wrap .k-select {
  border-color: $base-text;
  background-color: $base-bg;
  color: $base-text;
}

.k-combobox.dark-bg .k-dropdown-wrap:hover .k-select {
  border-color: $base-text;
  background-color: $base-bg;
  color: $accent;
}

.k-combobox.dark-bg .k-dropdown-wrap:hover .k-input {
  color: $accent;
}

.k-combobox.dark-bg .k-dropdown-wrap:hover {
  border-color: $accent !important;
}

.k-popup.ap-kendo-dropdown-button .k-item:hover {
  background-color: $accent;
}

.ap-grid-pager-size-dropdown .k-dropdown-wrap {
  height: 31px;
}

.k-list-scroller li.k-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ap-combobox-grouped-item {
  padding-left: 10px;
}
