@import "../variables";

.ap-cr-grid {
  height: calc(100% - 54px);
  overflow: hidden;
  border-top: none;
}

.ap-cr-headers {
  height: $cr-header-height;
}

.ap-cr-data {
  overflow: hidden;
}

ap-grid-pager.ap-cr-footer > div.ap-pager {
  background-color: $base-bg;
  color: $base-text;
}

.ap-cr-full-height > .k-i-loading {
  background-color: $block-bg-color;
}
