@import "../variables";

.k-textbox {
  color: $base-bg;
}

.ap-password-textbox {
  border: 0.0625rem solid $input-border-color;
  border-radius: 0.25rem;
  padding: 0;
  font-size: 1rem;
  background: $input-bg-color;
  line-height: $input-line-height;
  background: $input-bg-color;
  transition: $input-animation;
}

.ap-password-textbox > input {
  padding: 0.375rem 0.75rem;
}

.ap-password-textbox .k-i-preview {
  color: $input-border-color;
}

.ap-password-textbox .k-i-preview:hover,
.ap-password-textbox .k-i-preview:focus {
  color: $hovered-input-border-color;
}

input[kendotextbox]:not(.ap-password-textbox) {
  border: 0.0625rem solid $input-border-color;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background: $input-bg-color;
  line-height: $input-line-height;
}

input[kendotextbox]:hover, input[kendotextbox]:focus {
  border: 0.0625rem solid $hovered-input-border-color;
}

button[kendoButton] {
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid;
}

.ap-textbox-container {
  border: 0.0625rem solid $input-border-color;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background: $input-bg-color;
  line-height: $input-line-height;
  transition: $input-animation;
}

.ap-password-textbox:hover, .ap-password-textbox:focus,
.ap-textbox-container:hover, .ap-textbox-container:focus {
  border-color: $hovered-input-border-color;
}

.k-textbox-container {
  width: $full-percent;
  // Label
  div {
    color: $input-label-color;
    font-size: $input-label-font-size;
    font-weight: $input-label-font-weight;
  }
}

.k-textbox:disabled,
.k-textbox[disabled],
.k-textbox.k-state-disabled,
.k-input.k-textbox:disabled,
.k-input.k-textbox[disabled],
.k-input.k-textbox.k-state-disabled,
.k-textarea:disabled,
.k-textarea[disabled],
.k-textarea.k-state-disabled {
  pointer-events: auto;
}

.k-textarea {
  color: $base-bg !important;
}

.k-textbox.dark-bg {
  background-color: $base-bg !important;
  color: $base-text !important;
  border-color: $base-text !important;
}

.k-textbox.dark-bg:hover,
.k-textbox.dark-bg:focus,
.k-textbox.dark-bg:active {
  background-color: $base-bg !important;
  color: $accent !important;
  border-color: $accent !important;
}

.k-upload-files .k-file-name,
.k-upload .k-file-progress .k-file-name {
  color: $text-color;
}
