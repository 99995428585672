@import "../variables";

.ap-btn-link {
  color: $hovered-bg;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.ap-btn-link:hover, .ap-btn-link:focus {
  font-weight: 700;
}
