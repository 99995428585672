@import "../variables";

.k-filter-menu .k-filter-menu-container {
  padding: 5px;
}

.k-filter-menu .k-action-buttons {
  padding: 5px 2px 0;
  margin: 0;
  justify-content: space-between;
}

.k-filter-menu.k-popup .k-filter-menu-container, .k-grid-filter-popup.k-popup .k-filter-menu-container {
  width: auto;
  min-width: 230px;
  max-width: 430px;
}

// grid filter icon appearance
.k-grid-header .k-grid-filter,
.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-grid-filter.k-state-active,
.k-grid-header .k-grid-filter.k-state-active:hover {
  background-color: transparent;
}

.k-grid-header .k-grid-filter.k-state-active:hover,
.k-grid-header .k-grid-filter:hover {
  color: $hovered-button-bg-color;
}

.k-grid-header .k-grid-filter.k-state-active {
  color: $button-bg-color
}

//avoid that filter icon covers sort-arrow when grid gets too small
.k-filterable > .k-cell-inner .k-link {
  overflow: visible;
}

