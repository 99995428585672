@import "../variables";

.k-progressbar {
  border-radius: 0;
  height: 15px;
  width: 100%;
  background-color: transparent;
  border: 0;
}

ap-login-form .k-progressbar {
  position: absolute;
  left: 0;
  bottom: 0;
}
