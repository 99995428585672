@import "../variables";

.k-datepicker .k-select, .k-timepicker .k-select, .k-datetimepicker .k-select, .k-dateinput .k-select {
  color: $base-text;
  background-color: $base-bg;
}

.k-time-container > ul {
  color: $base-bg;
}

.k-time-highlight {
  background-color: $accent;
}

.k-datepicker .k-select:hover, .k-datepicker .k-select.k-state-hover, .k-timepicker .k-select:hover, .k-timepicker .k-select.k-state-hover {
  background-color: $base-bg;
}

.ap-datepicker {
  background-color: $bg-color;
  color: $text-color;
  padding: 2px !important;
  border: $small-border-size solid $bg-color !important;
  border-radius: 5px !important;
}

.k-datepicker.dark-bg .k-select {
  background-color: $base-bg;
  color: $base-text;
}

.k-datepicker.dark-bg .k-picker-wrap {
  border-color: $base-text;
}

.k-datepicker.dark-bg .k-picker-wrap .k-dateinput .k-dateinput-wrap {
  background-color: $base-bg;
  color: $base-text;
}

.k-datepicker.dark-bg .k-picker-wrap:hover {
  border-color: $accent;
}

.k-datepicker.dark-bg .k-picker-wrap:hover .k-input {
  color: $accent;
}

.k-datepicker.dark-bg .k-picker-wrap:hover .k-select {
  color: $accent;
}

.k-dateinput .k-dateinput-wrap {
  border-color: $input-border-color;
  background-color: $base-text;
  color: $base-bg;
}

.k-dateinput .k-dateinput-wrap:hover {
  border-color: $base-bg;
  background-color: $base-text;
  color: $base-bg;
}

.k-calendar .k-nav-today {
  color: $accent;
}

.k-calendar .k-nav-today:hover, .k-calendar .k-nav-today.k-state-hover, .k-calendar .k-nav-today:focus, .k-calendar .k-nav-today.k-state-focus {
  color: $accent;
}

.k-calendar .k-calendar-th, .k-calendar .k-calendar-caption, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  color: $base-text;
}

.k-calendar-td {
  border-radius: 0;
}

table.k-calendar-content {
  border-bottom: $small-border-size solid $base-bg;
}

table.k-calendar-content:nth-child(1) {
  border-right: $small-border-size solid $base-bg;
}

table.k-calendar-content:nth-child(2) {
  border-left: $small-border-size solid $base-bg;
}

.k-calendar .k-calendar-navigation li:hover, .k-calendar .k-calendar-navigation li.k-state-hover {
  color: $accent;
}

.k-calendar .k-calendar-view .k-today {
  color: $accent;
}

.k-calendar-title:hover {
  color: $accent;
}
