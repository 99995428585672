@import "../variables";

ap-dynforms .k-grid {
  height: auto;
}

.no-details .k-hierarchy-cell a.k-icon {
  display: none;
}

.ap-grid-max-height .k-grid {
  height: 100%;
  max-height: 100%;
}

.k-grid {
  height: calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
  max-height: calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
  border: 0;
  background-color: $blank;
}

.k-grid .k-hierarchy-col {
  width: 30px;
}

.k-grid tbody tr {
  height: 30px;
}

.k-grid .k-grid-aria-root .k-button,
.k-grid .k-grid-aria-root .k-dropdownbutton {
  height: 23px;
  width: 23px;
  margin-top: -2px;
  margin-bottom: -2px;
}

.k-grid .k-grid-aria-root .k-button .k-image,
.k-grid .k-grid-aria-root .k-dropdownbutton .k-image {
  min-width: 17px;
  width: 17px;
  min-height: 17px;
  height: 17px;
}

.k-grid .k-grid-aria-root .k-button .k-icon,
.k-grid .k-grid-aria-root .k-dropdownbutton .k-icon {
  font-size: 13px;
}

.k-grid-header {
  font-weight: 400;
}

.k-grid .k-indicator-container:last-child {
  font-weight: 200;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: $medium-border-size*2;
}

.k-grid .k-grid-header thead tr .k-header {
  border-left-width: $small-border-size;
  border-right-width: 0;
  border-left-style: solid;
  border-right-style: solid;
  border-top-width: 0;
  border-bottom-width: 0;
}

.k-grid.details .k-grid-header tr:first-child .k-header,
.k-grid .k-grid-header thead tr:first-child .k-header {
  border-image: linear-gradient(to top, $grid-header-border-color 60%, rgba(0, 0, 0, 0)) 1 100%;
}

.k-grid.details .k-grid-header tr:not(first-child) .k-header,
.k-grid .k-grid-header thead tr:not(first-child) .k-header {
  border-color: $grid-header-border-color;
}

.k-grouping-header,
.k-pager-wrap,
.k-grid .k-grid-header-wrap,
div.k-grid-header, div.k-grid-footer {
  border-width: 0;
}

.k-pager-wrap.k-grid-pager {
  padding: $medium-border-size;
}


.k-pager-nav.k-link:focus {
  box-shadow: none;
}

.k-pager-nav.k-link:focus:hover {
  background-color: $accent;
}

.k-pager-info {
  flex: 0 1 0;
}

table > tbody > tr {
  background-color: $base-text;
  color: $base-bg;
}

.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {
  background-color: $accent-rgba-025;
}

.k-grid-content table, .k-grid-content-locked table {
  background-color: $base-text;
}

.k-grid th {
  //padding: 6px 16px 6px 8px;
  padding-left: 6px;
}

.k-grid td {
  border-bottom: $small-border-size solid rgba(0, 0, 0, 0.00);
  border-left-color: rgba(0, 0, 0, 0.00);
  padding: 0 5px;
}

.k-grid tr.error td {
  border-bottom-color: $error;
  border-top-color: $error;
  border-top-width: 1px;
}

.k-grid tr.error + tr.error td {
  border-top-width: 0;
}

.k-grid .column-group-button .k-icon {
  color: $base-text !important;
  font-size: 20px !important;
}

.k-grid .column-group-button .k-icon:hover {
  color: $hovered-button-bg-color !important;
}

.k-grid .column-group-button {
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  border-width: 0;
}

.k-header > .k-checkbox {
  margin-bottom: 3px;
}

/* Grid Loading Indicator */

kendo-grid-list .k-loading-mask .k-loading-image {
  color: $base-bg;
  background-color: $block-bg-color;
}

/* Master Detail Grid */
.k-master-row .k-hierarchy-cell .k-icon {
  color: $base-bg;
}

table > thead > tr > th.k-header {
  background-color: $base-bg;
  color: $base-text;
}

.k-header > .k-cell-inner > .k-link,
.k-header > .k-cell-inner {
  align-items: flex-end;
}

.k-grid .k-command-cell {
  justify-content: center;
}

.k-grid-header .k-grid-filter {
  margin-bottom: 3px;
}

.k-grid-header .k-sort-order {
  height: 18px;
  display: none;
}

/* Grouping Header */

.k-grid .k-group-col {
  width: 29px;
}

.k-grouping-header > .k-indicator-container > .k-group-indicator {
  border-color: $base-text;
}

.k-grouping-header > .k-indicator-container:hover > .k-group-indicator {
  border-color: $accent;
  color: $accent;
}

/* Grouping Content */

.k-group-footer td, .k-grouping-row td, tbody .k-group-cell {
  color: $base-bg;
  background-color: $base-bg-bright;
}

.k-grid .k-grouping-row .k-icon {
  color: $base-bg;
}

.k-grid .k-grouping-row .k-icon:hover {
  color: $accent;
}

.k-grid th {
  padding: 6px 16px 6px 5px;
}

.k-grid-content,
.k-grid tr {
  background-color: $blank;
}

.k-grid tr.k-alt {
  background-color: $light-bg-color;
}

.k-pdf-export .k-grid td.k-state-selected,
.k-pdf-export .k-grid tr.k-state-selected > td {
  background-color: transparent;
}

.k-pdf-export .k-grid tbody tr:not(.k-state-selected) {
  display: none;
}

//add bottom border to last row in grid (but not when message 'no records found' is displayed)
.k-grid tr:last-child:not(.k-grid-norecords) td {
  border-bottom-width: $small-border-size;
}

.k-grid.details td:first-child {
  border-left-width: $small-border-size;
}

.k-grid.details .k-grid-header {
  padding: 0 !important;
}

//master-detail grid: detail grid without footer
.k-grid.details .k-grid-pager {
  height: 0;
  padding: 0;
}

.k-grouping-header {
  height: $ap-grid-line-height;
  padding-top: 6px;
  padding-bottom: 6px;
}

.k-grouping-header .k-icon {
  color: $base-text !important;
}

// Content alignment:
ap-status-switch-column-cell .status-switch {
  display: flex;
}

.k-grid .k-alt .k-grid-content-sticky,
.k-grid .k-grid-row-sticky.k-alt td,
.k-grid .k-grid-content-sticky,
.k-grid tr:hover .k-grid-content-sticky {
  border-color: $grid-header-border-color;
}

.k-grid tr:hover .k-grid-content-sticky {
  background-color: unset;
}

.k-grid .k-alt .k-grid-content-sticky,
.k-grid .k-grid-row-sticky.k-alt td {
  background-color: $light-bg-color;
}

.k-grid .k-grid-content-sticky {
  background-color: $blank;
}

ap-class-color-column-cell > .cell-content-center {
  margin-left: -5px;
  margin-right: -5px;
}

.k-detail-row > td {
  padding: 0;
}

.k-grid-header .k-grid-filter:focus {
  box-shadow: none;
}

/****************************************************************************************************************
 * Statistic grid layout
 ****************************************************************************************************************/

/** General **/
.ap-statistic-grid .k-grid-content {
  overflow: hidden !important;
}

.ap-statistic-grid .k-grid {
  background-color: transparent !important;
}

.ap-statistic-grid .k-grid-header {
  padding: 0 !important;
}

/** Header **/
.ap-statistic-grid table > thead > tr > th.k-header,
.ap-statistic-grid .k-grid-header .k-header {
  background-color: silver !important;
  color: black !important;
  font-weight: 600;
}

/** Rows **/

/*.ap-statistic-grid .k-grid tr.k-alt:hover {
  background-color: transparent !important;
}*/

.ap-statistic-grid .k-grid tr.k-alt:hover,
.ap-statistic-grid .k-grid tr.k-alt {
  background-color: white !important;
}

.ap-statistic-grid .k-grid tr:hover,
.ap-statistic-grid .k-grid tr {
  background-color: transparent !important;
}

.ap-statistic-grid-bold-footer-row .k-grid tr:last-child:not(.k-grid-norecords) td {
  background-color: silver;
  font-size: 14px;
  font-weight: 600;
}

.ap-statistic-grid ap-property-column-header {
  width: 100%;
}

ap-dyngrids .k-grid th, .k-grid td {
  text-overflow: initial !important;
}

ap-dyngrids .cell-content-center {
  text-align: center;
  display: flex;
  justify-content: center;
}

ap-dyngrids .cell-content-left {
  text-align: left;
  display: flex;
  justify-content: left;
}

ap-dyngrids .cell-content-right {
  text-align: right;
  display: flex;
  justify-content: right;
}

ap-dyngrids .checkbox-cell-center {
  text-align: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important
}

ap-dyngrids .small-column {
  width: 32px;
  padding: 0 !important
}

ap-dyngrids .ap-grid-group-header {
  text-align: center !important;
  padding-left: 0 !important;
  padding-right: 0 !important
}

ap-dyngrids .cell-content-left.italic,
ap-dyngrids .cell-content-right.italic,
ap-dyngrids .cell-content-center.italic{
  font-style: italic;
}

ap-dyngrids .cell-content-left.bold,
ap-dyngrids .cell-content-right.bold,
ap-dyngrids .cell-content-center.bold{
  font-weight: bold
}

ap-dyngrids .cell-content-right.italic span {
  padding-right: 2px;
}

// Start Details grid styles
ap-dyngrids.ap-details-grid .k-grid-content {
  overflow-y: hidden;
}

.ap-grid-bright-header .k-grid-header,
.ap-grid-bright-header th.k-header,
ap-dyngrids.ap-details-grid th.k-header {
  background-color: $grid-header-details-bg-color;
  color: black;
}

.ap-grid-bright-header .k-grid-aria-root,
ap-dyngrids.ap-details-grid .k-grid-aria-root {
  border: 1px #c0c0c0 solid;
}

ap-dyngrids.ap-details-grid .k-link {
  color: black;
}

ap-dyngrids.ap-details-grid .k-grid-header tr:first-child .k-header:first-child,
ap-dyngrids.ap-details-grid .k-grid-header thead tr:first-child .k-header:first-child {
  border-image: none;
}

ap-dyngrids.ap-details-grid .k-grid-header tr:first-child .k-header,
ap-dyngrids.ap-details-grid .k-grid-header thead tr:first-child .k-header {
  border-color: transparent;
}
// End Details grid styles


// Star Tooltip styles
.k-tooltip {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(44, 50, 55, 1);
}

.k-tooltip .k-callout {
  color: rgba(255, 255, 255, 1);
}

.k-tooltip.k-widget {
  border-width: 1px;
}
// End Tooltip styles

//Grid sorting and filtering styles
th:has(span kendo-grid-filter-menu a.k-state-active) {
  background-color: $grid-header-selected;
}

ap-dyngrids.ap-details-grid th:has(span kendo-grid-filter-menu a.k-state-active) {
  background-color: $grid-header-details-selected;
}

ap-dyngrids.ap-details-grid .k-grid-header .k-grid-filter.k-state-active {
  color: $grid-header-details-filter-color!important;
}

//End Grid sorting and filtering styles
