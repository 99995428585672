@import "../variables";

.ap-legend-scroller {
  position: absolute;
  display: flex;
  align-items: flex-end;
  z-index: $z-mid;
  bottom: 5px;
  right: 5px;
  max-width: calc(100% - 10px);
  margin-bottom: -5px;
}

.ap-legend-scroller-content {
  display: flex;
  margin-bottom: 12px;
}

.ap-legend {
  font-size: 1.2em;
  text-align: center;
  background: $button-bg-color;
  border: $small-border-size solid $button-border-color;
  color: $text-color;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  cursor: pointer;
}

.ap-legend:hover, .ap-legend:focus {
  border-color: $hovered-button-border-color;
  color: $text-color;
}

.ap-legend-scroll-button {
  font-size: 1.2em;
  background: $button-bg-color;
  border: $small-border-size solid $button-border-color;
  color: $text-color;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 35px;
  height: 35px;
}

.ap-legend-scroll-button:hover,
.ap-legend-scroll-button:focus {
  background: $hovered-button-bg-color;
  border-color: $hovered-button-border-color;
}

.ap-legend-scroll-button > span:nth-child(2) {
  margin-left: -10px;
}

.ap-legend-scroller-scrollable-view {
  padding: 0 5px;
}

.ap-legend-scroller-item-wrapper {
  display: block;
  width: 145px;
  margin: 0 2.5px;
  max-height: 400px;
}

ap-legend-scroller-item {
  display: flex;
  align-items: flex-end;
  color: $base-text;
}

.ap-legend-scroller-item-popup {
  background: $bg-color;
  border: $small-border-size solid $text-color;
  color: $text-color;
  padding: 5px;
  overflow: hidden;
  max-height: 350px;
}

.ap-legend-values {
  max-height: 400px;
  min-height: 100px;
  width: 133px;
  padding: 5px 0;
}

.ap-legend-color-item {
  display: flex;
  padding-right: 15px;
}

.ap-legend-color-item > ap-responsive-text {
  padding-top: 0;
  padding-left: 5px;
  font-size: 0.95em;
  width: 60.61%;
}

.ap-legend-color {
  width: 24%;
  height: 18px;
  margin: 0 5px;
  border-top: $small-border-size solid $text-color;
  border-left: $small-border-size solid $text-color;
  border-right: $small-border-size solid $text-color;
  border-bottom: none;
}

.open-border-top {
  border-bottom: $small-border-size solid $text-color;
}

.ap-legend-values-selection > .k-combobox {
  width: 133px;
}

.ap-legend-values-unit {
  padding: 5px;
  direction: rtl;
}

ap-legend-scroller-item .ap-legend-values-selection .k-state-disabled {
  opacity: 1.0;
  box-shadow: inherit;
}

ap-legend-scroller-item .ap-legend-values-selection .k-state-disabled .k-select {
  display: none;
}

ap-legend-scroller-item .k-dropdown-wrap.k-state-disabled {
  border: none;
}
