@import "../variables";

.k-time-header,
.k-time-list-wrapper,
.k-time-list-container {
  background-color: $light-bg-color;
}

.k-time-header {
  color: $text-color;
}

.k-calendar .k-calendar-navigation,
.k-time-list-wrapper .k-title {
  background-color: $input-border-color;
  color: $base-text;
}

.k-animation-container.k-animation-container-shown .k-calendar .k-state-selected {
  background-color: #b0cb1f80;
}

.k-calendar .k-state-selected .k-link,
.k-calendar .k-calendar-view .k-calendar-weekdays th {
  color: $base-text;
}

kendo-popup .k-calendar .k-state-selected .k-link {
  background-color: transparent;
}

.k-popup.ap-kendo-dropdown-button {
  background-color: $bg-color;
  color: $text-color;
  font-size: $main-font-size;
  font-family: $main-font-family;
}

.k-notification-group {
  top: $medium-border-size !important;
  z-index: $z-force;
}

.ap-notification-close-button {
  padding-left: 5px;
  cursor: pointer
}

.ap-task-list-popup {
  width: 250px;
  max-width: 250px;
}

.k-notification-wrap {
  font-size: 14px;
  align-items: center;
}

.k-calendar .k-state-hover .k-link, .k-calendar td:hover span.k-link {
  background-color: #b0cb1f80;
}

.k-calendar.k-calendar-range .k-range-start .k-link, .k-calendar.k-calendar-range .k-range-end .k-link {
  background-color: #b0cb1f80;
}

.k-calendar.k-calendar-range .k-range-start, .k-calendar.k-calendar-range .k-range-end, .k-calendar.k-calendar-range .k-range-mid {
  background-image: linear-gradient(transparent #{$small-border-size}, #b0cb1f40 #{$small-border-size}, #b0cb1f80 calc(100% - #{$small-border-size}), transparent calc(100% - #{$small-border-size}));
}

kendo-dialog-actions.k-dialog-buttongroup.k-dialog-button-layout-stretched button.k-button {
  display: flex;
}

.k-animation-container > .k-popup {
  border-color: $base-bg;
}

.k-action-buttons > .k-button {
  border-color: $base-text;
  color: $base-text;
  background-color: $base-bg;
  border-radius: $border-radius;
}

.k-action-buttons > .k-button:hover,
.k-action-buttons > .k-button:focus,
.k-action-buttons > .k-button:active {
  border-color: $accent;
  color: $accent;
}

.k-action-buttons > .k-primary {
  border-color: $base-text;
  color: $base-text;
  background-color: $base-bg;
  border-radius: $border-radius;
}

.k-action-buttons > .k-primary:hover,
.k-action-buttons > .k-primary:focus,
.k-action-buttons > .k-primary:active {
  border-color: $accent;
  color: $accent;
  background-color: $base-bg;
}

ap-map-burger-menu .k-animation-container-shown {
  border: 1px solid;
}

ap-map-burger-menu .k-popup,
ap-map-burger-menu .k-popup .k-panelbar {
  border: 0;
  font-family: $main-font-family;
  font-weight: normal;
  font-size: 15px;
}

ap-map-burger-menu .k-popup .k-panelbar {
  color: $text-color;
  background-color: $blank;
}

ap-map-burger-menu .k-popup .ap-layer-control.k-panelbar > .k-panelbar-header {
  border-top: 1px solid $text-color !important;
}

ap-map-burger-menu .k-panelbar layer-controller-map-selector .k-label,
ap-map-burger-menu .k-panelbar > .k-panelbar-header .k-link {
  cursor: pointer;
}

ap-map-burger-menu .k-panelbar .k-link,
ap-map-burger-menu .k-panelbar .k-link.k-state-selected {
  color: $text-color !important;
}
