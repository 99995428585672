@import "../variables";

.ol-control,
.ol-control:hover {
  background: transparent;
  padding: 0;
}

.ol-control button {
  color: $hovered-bg;
  background-color: $button-bg-color !important;
  border: $small-border-size solid $button-border-color !important;
  line-height: $button-line-height;
  width: 25px;
  height: 25px;
}

.ol-control button:hover,
.ol-control button:focus {
  color: $hovered-bg;
  background-color: $hovered-button-bg-color !important;
  border: $small-border-size solid $hovered-button-border-color !important;
}

.ol-attribution {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

ap-map-tree-view {
  background-color: transparent;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-flex;
}

#mapTreeView {
  opacity: 0.5;
}

#mapTreeView label.k-checkbox-label {
  display: block;
}

#mapTreeView li {
  height: fit-content;
}

#mapTreeView li[ng-reflect-is-checked="indeterminate"] label:after {
  background-color: $button-border-color;
}

#mapTreeView li,
#mapTreeView li span,
#mapTreeView li span.k-state-selected {
  background: $blank;
}

#mapTreeView li span.k-state-selected {
  color: $text-color;
  background: $selected-text;
}

.field_edit_area {
  color: black;
  background-color: #fff;
  padding: 3px 5px;
  border-radius: 20px;
  font: 12px/12px Titillium Web;
}

.ap-map-tooltip {
  pointer-events: none;
  position: absolute;
  z-index: $z-tooltip;
}

.ol-scale-line {
  background-color: rgba(44, 50, 55, 0.7);
}
