@import "../variables";

.ap-note-list {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: $z-force;
  background: transparent;
  width: 350px;
}

.ap-note-success,
.ap-note-info,
.ap-note-warning,
.ap-note-news,
.ap-note-error {
  line-height: 50px;
  padding-right: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 2px solid;
  cursor: pointer;
}

.ap-note-error {
  background: $base-bg;
  color: $base-text;
  border-color: $error;
}

.ap-note-warning {
  background: $base-bg;
  color: $base-text;
  border-color: $warning;
}

.ap-note-info {
  background: $base-bg;
  color: $base-text;
  border-color: $info;
}

.ap-note-success {
  background: $base-bg;
  color: $base-text;
  border-color: $success;
}

.ap-note-news {
  background: $base-bg;
  color: $base-text;
  border-color: $success;
}

.ap-notify-icon-info,
.ap-notify-icon-warning,
.ap-notify-icon-error,
.ap-notify-icon-news,
.ap-notify-icon-success {
  margin-right: 5px;
  border-radius: 5px;
  padding: 15px 0px 15px 4px;
}

.ap-notify-icon-info {
  background-color: $info;
}

.ap-notify-icon-warning {
  background-color: $warning;
}

.ap-notify-icon-error {
  background-color: $error;
}

.ap-notify-icon-success {
  background-color: $success;
}

.ap-notify-icon-news {
  background-color: $success;
}

.ap-notify-icon-success svg,
.ap-notify-icon-info svg,
.ap-notify-icon-warning svg,
.ap-notify-icon-news svg,
.ap-notify-icon-error svg {
  width: 1.4rem;
  height: 2rem;
  position: relative;
  top: 0.5rem;
  padding-left: 5px;
  padding-right: 10px;
}
