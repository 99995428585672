@import "../variables";

.k-tabstrip > .k-content {
  color: $base-bg;
  background-color: $base-text;
}

.k-tabstrip-items-wrapper {
  display: flex;
  flex-direction: column;
}

.ap-tabstrip-small,
.ap-tabstrip,
.ap-tabstrip-with-arrows {
  height: -webkit-calc(#{$full-percent});
  height: -moz-calc(#{$full-percent});
  height: calc(#{$full-percent});
  width: 100%;
}

.ap-tabstip-arrows {
  padding-top: 7px;
  display: flex;
}

.ap-tabstrip-arrows-chevron {
  margin-left: -10px;
}

div.ap-tabstrip-with-arrows {
  kendo-tabstrip {
    .k-tabstrip-items {
      margin-left: $ap-tabstrip-arrow-button-size;
      margin-right: $ap-tabstrip-arrow-button-size;
      width: -webkit-calc(#{$full-percent} - (#{$ap-tabstrip-arrow-button-size} * 2));
      width: -moz-calc(#{$full-percent} - (#{$ap-tabstrip-arrow-button-size} * 2));
      width: calc(#{$full-percent} - (#{$ap-tabstrip-arrow-button-size} * 2));
    }
  }
}

.ap-tabstrip-header {
  color: $base-bg;
}

.ap-tabstrip-icon {
  svg {
    height: $icon-size;
    width: $icon-size;
    margin-right: 0.2rem;
    position: relative;
    top: 0.3rem;

    polygon {
      fill: $base-bg;
      stroke: $base-bg;
    }

    path {
      fill: $base-bg;
      stroke: $base-bg;
    }

    rect {
      fill: $base-bg;
      stroke: $base-bg;
    }
  }
}

.k-tabstrip-items .k-link {
  justify-content: center;
}

.ap-tabstrip > kendo-tabstrip > ul,
.ap-tabstrip-with-arrows > kendo-tabstrip > ul {
  line-height: 2.5rem;
}

.ap-tabstrip-small > kendo-tabstrip > ul,
.ap-tabstrip > kendo-tabstrip > ul,
.ap-tabstrip-with-arrows > kendo-tabstrip > ul {
  text-align: center;
  width: 100%;
}

.ap-tabstrip-small .k-grid {
  height: 100%;
  max-height: 100%;
}

.ap-tabstrip-small kendo-tabstrip.k-tabstrip {
  height: -webkit-calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
  height: -moz-calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
  height: calc(#{$full-percent} - #{$header-height} - #{$header-padding} * 2);
}

.ap-tabstrip kendo-tabstrip.k-tabstrip,
.ap-tabstrip-with-arrows kendo-tabstrip.k-tabstrip {
  height: -webkit-calc(#{$full-percent});
  height: -moz-calc(#{$full-percent});
  height: calc(#{$full-percent});
}

.ap-tabstrip-small ul li span.k-link,
.ap-tabstrip ul li span.k-link,
.ap-tabstrip-with-arrows ul li span.k-link {
  width: 100%;
  box-sizing: border-box;
  min-width: 5px;
}

.ap-tabstrip ul.k-tabstrip-items li.k-item,
.ap-tabstrip-with-arrows ul li.k-item {
  width: $content-view-width;
  height: 2.92rem;
  font-size: 1rem;
}

.ap-tabstrip-small ul li.k-item,
.ap-tabstrip ul.k-tabstrip-items li.k-item,
.ap-tabstrip-with-arrows ul li.k-item {
  text-align: center;
  color: $text-color;
  font-weight: $tab-label-font-weight;
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
  border-bottom-color: $blank;
  border-bottom-width: $ap-tabstrip-bottom-border-width;
}

.ap-tabstrip-small kendo-tabstrip.k-tabstrip .k-content,
.ap-tabstrip kendo-tabstrip.k-tabstrip .k-content,
.ap-tabstrip-with-arrows kendo-tabstrip.k-tabstrip .k-content {
  padding: 0 !important;
  margin: 0;
  border: 0;
  height: -webkit-calc(#{$full-percent});
  height: -moz-calc(#{$full-percent});
  height: calc(#{$full-percent});
  overflow-x: hidden;
  overflow-y: auto;
}

.ap-tabstrip .k-tabstrip-top > .k-tabstrip-items-wrapper {
  margin-bottom: 0;
}

.ap-tabstrip-arrow-button-tab {
  max-width: 34px;
}

.ap-tabstrip-small ul li.k-item.k-state-active,
.ap-tabstrip ul li.k-item.k-state-active,
.ap-tabstrip-with-arrows ul li.k-item.k-state-active {
  border-bottom-color: $accent !important;
  // border-bottom-width: 6px; //0.3875rem;
  font-weight: $tab-label-font-weight-active;
}

.k-tabstrip-items-wrapper .k-item:hover, .k-tabstrip-items-wrapper .k-item.k-state-hover,
.ap-tabstrip-arrow-button-tab:hover,
.k-tabstrip .k-item.k-state-active {
  background-color: $bg-color;
  color: $base-bg;
}

.k-tabstrip-top > .k-content,
.k-tabstrip-top > .k-tabstrip-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
