@import "../variables";

.ap-export-pdf-page-template {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ap-export-pdf-page-template-header {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  border-bottom: $small-border-size solid #888;
  color: #888;
}

.ap-export-pdf-page-template-footer {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  border-top: $small-border-size solid #888;
  text-align: center;
  color: #888;
}

kendo-pdf-document .k-grid.k-widget {
  height: 100%;
  max-height: 100%;
}


@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}
